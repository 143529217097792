<template lang="pug">
  el-dialog.preview-dialog(:visible.sync="visible" :title="'Предпросмотр страницы'")
    .preview-dialog__btn-group(v-loading="loading")
      a.preview-dialog__link(:href="fullLink" target="_blank")
        el-button.preview-dialog__link-btn(type="primary") Перейти
      el-button.preview-dialog__btn(
        type="success"
        @click="hide()"
      ) Продолжить редактирование
</template>

<script>
  export default {
    name: 'EntityCreateCreatedDialog',
    components: {
    },
    props: {
      link: { type: String, default: null }
    },
    data() {
      return {
        visible: false,
        loading: false,
        version: null
      }
    },
    computed: {
      fullLink() {
        if (!this.link) {
          return ''
        }
        return `${this.link.split('#')[0]}?v=${this.version}${this.link.split('#')[1] ? '#' + this.link.split('#')[1] : ''}`
      }
    },
    async created() {
    },
    methods: {
      isLoading() {
        return this.loading
      },
      show() {
        this.visible = true
      },
      hide() {
        this.visible = false
      },
      startLoading() {
        this.loading = true
      },
      stopLoading() {
        this.loading = false
      },
      showLoading() {
        this.startLoading()
        this.show()
      },
      open(version) {
        this.setVersion(version)
        this.show()
        this.stopLoading()
        this.openLink()
      },
      setVersion(version) {
        this.version = version
      },
      openLink() {
        window.open(this.fullLink, '_blank')
        // const win = window.open(this.fullLink, '_blank');
        // win.focus();
      }
    },
  }
</script>
<style lang="sass">
  @import "@/assets/sass/mixin.scss"
  .preview-dialog
    &__btn-group
      display: flex
      flex-flow: row nowrap
      justify-content: space-between
      width: 100%
      &:not(:last-child)
        margin-bottom: 10px
    &__link
      width: 49%
    &__link-btn
      width: 100%
    &__btn
      width: 49%
</style>
