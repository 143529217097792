<template lang="pug">
  el-scrollbar(wrap-class="scrollbar-wrapper")
    .sidebar-domain(v-if="!isCollapse")
      //- el-select(@change="$store.dispatch('app/setDomain', $event)" :value="$store.state.app.domain").sidebar-domain__select
      el-select(@change="changeDomain" :value="domain").sidebar-domain__select
        el-option(
          v-for="domain in $store.state.app.domains"
          :value="domain.name"
          :label="domain.title"
          :key="domain.name"
        )
    el-menu(
      :show-timeout="200"
      :default-active="$route.path"
      :collapse="isCollapse"
      mode="vertical"
      background-color="#304156"
      text-color="#bfcbd9"
      active-text-color="#409EFF"
    )
      el-submenu(v-if="isCollapse" index="domain").sidebar-collapsed-domain
        template(slot="title")
          i.el-icon-watermelon.sidebar-el-icon
        el-menu-item(v-for="domain in $store.state.app.domains" :key="domain.name" @click="changeDomain(domain.name)") {{ domain.title }}
      sidebar-item(v-for="route in routes" :key="route.path" :item="route" :base-path="route.path")
</template>

<script>
import { mapGetters } from 'vuex'
import SidebarItem from './SidebarItem'

export default {
  components: { SidebarItem },
  computed: {
    ...mapGetters([
      'sidebar',
      'permission_routers',
    ]),
    routes() {
      // console.log(this.$store.state.permission.routes);
      return this.$store.state.permission.routes
    },
    isCollapse() {
      return !this.sidebar.opened
    },
    domain() {
      return this.$route.path.match(/^\/([^/]+)/)[1]
    }
  },
  created() {
    this.updateStoreDomain()
  },
  methods: {
    changeDomain($event) {
      // console.log($event);
      const domain = this.$store.state.app.domains.filter(el => el.name === $event)[0]
      this.$router.push('/' + $event + (domain.indexPath || '/dashboard'))
    },
    updateStoreDomain() {
      this.$store.dispatch('app/setDomain', this.domain)
    }
  },
  watch: {
    domain() {
      this.updateStoreDomain()
    }
  }
}
</script>
<style lang="sass">
  .sidebar-container .sidebar-domain
    border-bottom: 1px solid rgb(153, 185, 200)

    &__select
      width: 100%
      height: auto
      .el-input__inner
        color: white
        background-color: rgb(3, 69, 101)
        border-width: 0px
        border-radius: 0px
        height: 60px
        padding: 0px 20px
        width: 100%

      // padding: 30px 10px
      .el-input__suffix
        right: 15px
  .sidebar-collapsed-domain
    border-bottom: 1px solid rgb(153, 185, 200)
    background-color: rgb(3, 69, 101)
    .el-submenu__title
      background-color: transparent!important
</style>
