import { login, logout, getInfo } from '@/api/auth'
import { getToken, setToken, removeToken } from '@/utils/auth'
import router, { resetRouter } from '@/router'
import store from '@/store'
const roles = window.__data.roles

const state = {
  id: null,
  token: getToken(),
  name: '',
  avatar: '',
  introduction: '',
  roles: [],
  roleName: '',
  user: null,
  permissions: [],
  allRoles: roles,
  allPermissionTypes: window.__data.permissionTypes
}

const mutations = {
  SET_ID: (state, id) => {
    state.id = id
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_USER: (state, user) => {
    state.user = user
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_ROLE_NAME: (state, roleName) => {
    state.roleName = roleName
  },
  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions
  },
}

const actions = {
  setToken({ commit }, token) {
    commit('SET_TOKEN', token)
    setToken(token)
  },

  // user login
 /* login({ commit }, userInfo) {
    const { email, password } = userInfo;
    return new Promise((resolve, reject) => {
      login({ email: email.trim(), password: password, secret: userInfo.secret || '', googleRecaptchaToken: userInfo.googleRecaptchaToken || '' })
        .then(response => {
          const token = response.data && response.data.token ? response.data.token : null;
          if (token) {
            commit('SET_TOKEN', token);
            setToken(token);
          }
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },*/

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      const data = window.__data.user
      if (!data) {
        removeToken()
        commit('SET_TOKEN', null)
        reject('Вы не авторизованы!')
      } else {
        const { role, name, email, role_name/*, avatar*/, introduction, permissions, id } = data
        // roles must be a non-empty array
        // if (!roles || roles.length <= 0) {
        //   reject('getInfo: roles must be a non-null array!');
        // }

        commit('SET_ROLES', [role])
        commit('SET_PERMISSIONS', permissions)
        commit('SET_NAME', name)
        commit('SET_ROLE_NAME', role_name)
        commit('SET_USER', data)
        // commit('SET_AVATAR', avatar);
        // commit('SET_INTRODUCTION', introduction);
        commit('SET_ID', id)
        resolve(data)
      }
    })
  },

  // user logout
  async logout({ commit, state }) {
    const response = await logout()
    commit('SET_TOKEN', null)
    commit('SET_ROLES', [])
    removeToken()
    resetRouter()
  },

  // remove token
  resetToken({ commit }) {
    commit('SET_TOKEN', '')
    commit('SET_ROLES', [])
    removeToken()
  },

  // Dynamically modify permissions
  async changeRoles({ commit, dispatch }, role) {
    const roles = [role.name]
    const permissions = role.permissions.map(permission => permission.name)
    commit('SET_ROLES', roles)
    commit('SET_PERMISSIONS', permissions)
    resetRouter()

    // generate accessible routes map based on roles
    const accessRoutes = await store.dispatch('permission/generateRoutes', { roles, permissions })

    // dynamically add accessible routes
    router.addRoutes(accessRoutes)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
