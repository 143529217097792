<template lang="pug">
  .flexible-field
    .repeater-field__top-actions
      el-button(@click="openCodeModal()") Код поля

    table.flexible-field__table
      thead(v-if="!currentValue.length")
        tr.flexible-field__row
          td.flexible-field__field.flexible-field__field-action
            span.flexible-field__empty-text Пустой список
        tr.flexible-field__row
          td.flexible-field__field.flexible-field__field-action
            flexible-block-add(:blocks="field.blocks" @add="handleInsert(0, $event)")

      draggable(
        tag="tbody"
        v-model="currentValue"
        v-bind="dragOptions"
        @start="drag = true"
        @end="drag = false"
      )
        tr.flexible-field__row.flexible-field__draggable-item(v-for="(row, rowIndex) in currentValue" :key="`${row._hash}`")
          td.flexible-field__field.flexible-field__field-index(style="min-width: 40px") {{ rowIndex + 1 }}
          td.flexible-field__field(style="width: 100%")
            .flexible-field__title {{ getBlock(row.block_name).title }}
            .flexible-field__block-row
              .flexible-field__block-field(v-for="(rowField, rowFieldIndex) in getBlock(row.block_name).fields" :key="rowFieldIndex")
                condition-field(:field="rowField" v-model="currentValue[rowIndex][rowField.name]" :allValues="currentValue[rowIndex]" v-if="getBlock(row.block_name).single_field === false")
                field(v-else :field="rowField" v-model="currentValue[rowIndex][rowField.name]")

          td.flexible-field__field.flexible-field__field-action
            flexible-block-add.flexible-field__field-action-button-plus(:blocks="field.blocks" @add="handleInsert(rowIndex, $event)" v-if="rowIndex !== currentValue.length - 1")
              el-button.flexible-field__field-action-button(
                icon="el-icon-plus"
                size="mini"
                circle
              )
            el-button.flexible-field__field-action-button.flexible-field__field-action-button-minus(
              icon="el-icon-minus"
              size="mini"
              circle
              @click.prevent="handleDelete(rowIndex)"
            )
            flexible-block-add.flexible-field__field-action-button-plus.flexible-field__field-action-button-plus_top(
              :blocks="field.blocks"
              v-if="rowIndex === 0"
              @add="handleInsert(rowIndex - 1, $event)"
            )
              el-button.flexible-field__field-action-button(
                icon="el-icon-plus"
                size="mini"
                circle
              )

          td.flexible-field__field.flexible-field__handle
            .flexible-field__handle-inner
              svg-icon(icon-class="move")
          // td.flexible-field__field.flexible-field__field-action
            el-button.flexible-field__field-action-button.flexible-field__field-action-button-up(
              icon="el-icon-arrow-up"
              size="mini"
              circle
              @click.prevent="handleUp(rowIndex)" :disabled="rowIndex === 0"
            )
            el-button.flexible-field__field-action-button.flexible-field__field-action-button-down(
              icon="el-icon-arrow-down"
              size="mini"
              circle
              @click.prevent="handleDown(rowIndex)" :disabled="rowIndex === currentValue.length - 1"
            )
      tfoot
        tr.flexible-field__row
          td.flexible-field__field.flexible-field__bottom-add(colspan="4")
            flexible-block-add.flexible-field__bottom-add-button(:blocks="field.blocks" @add="handleInsert(currentValue.length, $event)")

    field-meta(ref="meta" element-name="элементов" :len="currentValue.length" :min="field.min" :max="field.max" :errors="[]")

    el-dialog.code-dialog(:visible.sync="codeDialogVisible" :title="'Код поля'")
      .form-container
        el-form.edit-container
          el-input.code-dialog-input(v-model="codeDialogInput" rows="25" type="textarea")
        div(style="text-align:right;margin-top: 20px")
          el-button(type="danger" @click="codeModalCancel()") Отменить
          el-button(type="primary" @click="codeModalSave()") Сохранить
</template>
<script>
  import FlexibleBlockAdd from './FlexibleBlockAdd'
  import ConditionField from '@/components/fields/ConditionField'
  import Draggable from 'vuedraggable'
  import makeHash from '@/utils/make-hash'
  import FieldMeta from '@/components/fields/FieldMeta'

  export default {
    name: 'FlexibleField',
    components: { FieldMeta, FlexibleBlockAdd, ConditionField, Draggable },
    props: {
      field: { type: Object, default: () => ({}) },
      value: { type: [Object, Number, Array, String], default: null }
    },
    data() {
      return {
        drag: false,
        currentValue: this.copy(this.value) || this.copy(this.field.default) || null,
        codeDialogVisible: false,
        codeDialogInput: ''
      }
    },
    created() {
      this.emitInput()
    },
    watch: {
      currentValue: {
        deep: true,
        handler() {
          this.checkErrors()
          this.emitInput()
        }
      },
      value: {
        deep: true,
        handler() {
          if (!this.equal(this.currentValue, this.value)) {
            this.currentValue = this.copy(this.value)
          }
        }
      }
    },
    computed: {
      dragOptions() {
        return {
          animation: 200,
          draggable: '.flexible-field__draggable-item',
          handle: '.flexible-field__handle',
          disabled: false,
          ghostClass: 'flexible-field__ghost'
        }
      },
      lang() {
        return this.$store.getters.language
      },
      view() {
        return this.field.view === 'block' ? 'block' : 'table'
      }
    },
    methods: {
      emitInput() {
        this.$emit('input', this.copy(this.currentValue))
      },
      copy(value) {
        if (typeof value !== 'object') {
          return value
        }
        return JSON.parse(JSON.stringify(value))
      },
      equal(val1, val2) {
        if (typeof val1 !== 'object' || typeof val2 !== 'object') {
          return val1 === val2
        }
        return JSON.stringify(val1) === JSON.stringify(val2)
      },
      rowHash(row) {
        return Object.values(row).join('_')
      },
      getBlock(blockName) {
        return this.field.blocks.filter(el => el.name === blockName)[0]
      },
      defaultRow(blockName) {
        const row = this.getBlock(blockName).fields.reduce((row, el) => {
          row[el.name] = el.default || null
          return row
        }, {})
        row.block_name = blockName
        row._hash = makeHash(20)
        return JSON.parse(JSON.stringify(row))
      },
      handleInsert(index, blockName) {
        this.currentValue.splice(index + 1, 0, this.defaultRow(blockName))
      },
      handleDelete(index) {
        this.$confirm(`Удалить элемент №${index + 1}?`, 'Подтвердите удаление', {
          confirmButtonText: 'Удалить',
          cancelButtonText: 'Отменить',
          // type: 'warning'
        }).then(() => {
          this.currentValue.splice(index, 1)
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: 'Удаление отменено'
          // });
        })
      },
      handleUp(index) {
        if (index < 1 || index > this.currentValue.length - 1) {
          return
        }
        const before = { ...this.currentValue }
        this.currentValue.splice(index - 1, 2, this.currentValue[index], this.currentValue[index - 1])
        // console.log(index, this.currentValue, before);
      },
      handleDown(index) {
        if (index < 0 || index > this.currentValue.length - 2) {
          return
        }
        const before = { ...this.currentValue }
        this.currentValue.splice(index, 2, this.currentValue[index + 1], this.currentValue[index])
        // console.log(index, this.currentValue, before);
      },
      checkErrors() {
        this.$refs.meta.check()
      },
      openCodeModal() {
        this.codeDialogVisible = true
        this.codeDialogInput = JSON.stringify(this.currentValue, null, '  ')
      },
      codeModalSave() {
        // console.log(this.codeDialogInput.replace("\\n", '').substr(4740, 100));
        try {
          this.currentValue = JSON.parse(this.codeDialogInput)
          this.currentValue.forEach(el => el._hash = makeHash(20))
        } catch (err) {
          alert(err.message)
        }
        // eval('this.currentValue = ' + this.codeDialogInput);
        this.codeDialogVisible = false
      },
    },
  }
</script>
<style lang="sass">
  @import "./index.sass"
</style>
