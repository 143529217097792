<template lang="pug">
  .upload-file-single(
    :class="{'upload-file-single_empty': empty}"
  )
    el-upload(
      :multiple="true"
      :on-success="handleImageSuccess"
      :on-remove="handleRemove"
      :on-preview="handlePreview"
      drag
      :file-list="fileList"
      :with-credentials="true"
      :data="data"
      :headers="headers"
      :limit="3"
      :action="action"
    )
      .upload-file-single__inner
        i.el-icon-upload.upload-file-single__icon
        .el-upload__text.upload-file-single__text
          | Перенесите файл сюда или
          em &nbsp;Нажмите для загрузки
    .upload-file-single__link-row
      link-browser(
        v-if="field.allow_links"
        :base="$route.meta.model.replace(/__/g, '/').replace('options/', '')"
        :files="files"
        :limit="1"
        @input="emitInput($event[0]);$emit('load-link', $event[0])"
      )
      file-browser(
        :base="$route.meta.model.replace(/__/g, '/').replace('options/', '')"
        :files="files"
        :limit="1"
        @input="emitInput($event[0])"
      )
</template>

<script>
import hasUrlGetter from '@/utils/has-url-getter'
import FileBrowser from '@/components/Browser/FileBrowser'
import LinkBrowser from '@/components/Browser/LinkBrowser'
export default {
  name: 'SingleFileUpload',
  mixins: [hasUrlGetter],
  components: { FileBrowser, LinkBrowser },
  props: {
    value: {
      type: Object,
      default: null,
    },
    field: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    // console.log(this.value);
    return {
      tempUrl: '',
      additionalData: {},
      fileList: this.getFileList(),
      chanded: 0,
      empty: !this.value
    }
  },
  computed: {
    headers() {
      return {
        'Authorization': 'Bearer ' + this.$store.state.user.token
      }
    },
    data() {
      return {
        model: this.$route.meta.model,
        model_id: 0 // TODO: make work loading with model_id. this.$route.params.id
      }
    },
    action() {
      return process.env.VUE_APP_API_BASE + '/files/upload'
    },
    listType() {
      return this.field.list_type || 'picture-card'
    },
    url() {
      return this.value ? this.getUrl(this.value) : ''
    },
    files() {
      return this.value ? [this.value] : []
    }
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.empty = !this.value
        if (!this.currentValue && this.value) {
          this.changed = true
          this.fileList = this.getFileList()
        }
      }
    }
  },
  methods: {
    getFileList() {
      const image = this.value
      return image ? [{ ...image, url: this.getUrl(image.path) }] : []
    },
    getName(str) {
      const splitArr = str.split('/')
      return splitArr[splitArr.length - 1]
    },
    handleRemove(/* file, fileList */) {
      this.emitInput(null)
    },
    emitInput(val) {
      this.$emit('input', val)
    },
    handleImageSuccess(response) {
      // console.log('imageSuccess', response);
      this.emitInput(null)
      this.emitInput(response.data)
    },
    handleBeforeUpload() {
      this.empty = false
    },
    handlePreview(file) {
      window.open(this.getUrl(file.url), '_blank')
    },
  },
}
</script>
<style rel="stylesheet/sass" lang="sass">
@import "@/assets/sass/mixin.scss"
.upload-file-single
  z-index: 2
  &__link-row
    display: flex
    flex-flow: row nowrap
    justify-content: space-between
  &__inner
    width: 100%
    height: 100%
    display: flex
    flex-flow: row nowrap
  & &__icon
    line-height: 48px
    height: 50px
    width: 50px
    margin: 0 0 0 5px
    font-size: 44px
  &__text
    margin-left: 12px
    line-height: 50px
  .el-icon-document:before, .el-icon-circle-close:before, .el-icon-upload-success:before
    line-height: 28px
    font-size: 18px
    height: 28px
  .el-icon-close
    top: 0
    line-height: 28px
    font-size: 18px
    height: 28px
  .el-icon-close-tip
    top: 0
    line-height: 28px
    color: gray
  &_empty
    .el-upload-list
      display: none
  .el-upload-list
    height: 30px
    position: relative
    margin-top: 8px
    &__item
      border: 1px dashed #d9d9d9
      position: absolute
      top: 0
      left: 0
      &:first-child
        margin-top: 0
  .el-upload
    width: 100%
    display: block
  .el-list-enter, .el-list-leave-active
    opacity: 0
    transform: translateX(30px)
  .el-list-leave-active
    opacity: 0
  .el-upload-dragger
    width: 100%
    height: 50px
    .el-icon-upload
      display: block
      left: 10px

</style>
