<template lang="pug">
  .field-meta
    .field-meta__row
      .field-meta__right
        slot(name="right")
          .field-meta__info
            | Количество {{ elementName }}: {{ len }}
            template(v-if="min || max") , разрешено:&nbsp;
            template(v-if="min") от&nbsp;{{ min }}&nbsp;
            template(v-if="max") до&nbsp;{{ max }}&nbsp;
            template(v-if="min || max") {{ elementName }}
      .field-meta__left
        slot
          transition(name="field-meta__transition-fade")
            .field-meta__errors(v-if="innerErrors.length")
              span(
                v-for='(error, index) in innerErrors'
                :key='index'
              ).field-meta__error-text {{ error }}
</template>
<script>
export default {
  props: {
    elementName: { type: String, default: 'элементов' },
    len: { type: Number, default: null },
    min: { type: Number, default: null },
    max: { type: Number, default: null },
    checkLen: { type: Boolean, default: true },
    errors: { type: Array, default: () => [] }
  },
  data() {
    return {
      innerErrors: []
    }
  },
  mounted() {
    this.check()
  },
  methods: {
    async check() {
      await this.$nextTick()
      this.innerErrors = [...this.errors]
      if (this.checkLen) {
        if (this.len < this.min) {
          this.innerErrors.push(`Минимальное количество ${this.elementName} ${this.min}`)
        }
        if (this.len > this.max) {
          this.innerErrors.push(`Максимальное количество ${this.elementName} ${this.max}`)
        }
      }
    }
  }
}
</script>
<style lang="sass">
  .field-meta
    &__row
      display: flex
      justify-content: space-between
      flex-flow: row-reverse nowrap
      width: 100%
      font-size: 10px
      line-height: 14px
    &__left
      padding-left: 3px
      cursor: default
      padding-right: 5px
    &__errors
      border-spacing: 0
    &__error-text
      color: red

    &__right
      padding-right: 3px
      text-align: right
      cursor: default

    &__info
      color: #bababa

    &__transition-fade-enter-active, &__transition-fade-leave-active
      transition: opacity .25s

    &__transition-fade-enter, &__transition-fade-leave-to
      opacity: 0
</style>
