import { render, staticRenderFns } from "./MultipleImage.vue?vue&type=template&id=28aa8eee&lang=pug&"
import script from "./MultipleImage.vue?vue&type=script&lang=js&"
export * from "./MultipleImage.vue?vue&type=script&lang=js&"
import style0 from "./MultipleImage.vue?vue&type=style&index=0&id=28aa8eee&prod&rel=stylesheet%2Fscss&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports