<template lang="pug">
  .documents-section-field
    h4.documents-section-field__title {{ field.title_config.title }}
    text-field(v-model="currentValue.title" :field="field.title_config")
    .documents-section-field__additional-field(v-for="(rowField, rowFieldIndex) in field.additional_fields" :index="rowFieldIndex")
      condition-field(:field="rowField" v-model="currentValue[rowField.name]" :allValues="currentValue")
    h4.documents-section-field__documents {{ field.documents_config.title }}
    repeater-field(v-model="currentValue.documents" :field="field.documents_config" ref="documents")
</template>
<script>
  import TextField from './TextField'
  import RepeaterField from './RepeaterField'
  import ConditionField from './ConditionField'
  export default {
    name: 'DocumentsSectionField',
    props: {
      field: { type: Object, default: () => ({}) },
      value: { type: Object, default: () => ({}) }
    },
    components: {
      TextField,
      RepeaterField,
      ConditionField
    },
    data() {
      return {
        errors: [],
        currentValue: this.value
      }
    },
    created() {
      // logic moved to repeater
      this.$store.commit('page/ADD_DOCUMENTS_SECTION', this)
    },
    beforeDestroy() {
      this.$store.commit('page/REMOVE_DOCUMENTS_SECTION', this)
    },
    watch: {
      currentValue: {
        deep: true,
        handler() {
          this.$emit('input', this.currentValue)
        }
      },
      value: {
        deep: true,
        handler() {
          this.currentValue = this.value
        }
      }
    },
    computed: {
      lang() {
        return this.$store.getters.language
      }
    },
    methods: {
      unshift(el) {
        this.$refs.documents.unshift(el)
      },
      splice(start, deleteCount) {
        this.$refs.documents.splice(start, deleteCount)
      },
      getDocumentsSectionTitle() {
        return this.currentValue.title
      }
    }
  }
</script>
<style rel="stylesheet/sass" lang="sass">
#app
  .documents-section-field
    .condition-field__wide-title
      margin-top: 0.8em
    &__title
      margin-top: 0
      margin-bottom: 0.3em
      font-size: 16px
      color: #606266
      line-height: 1.15
    &__documents
      margin-top: 0.8em
      margin-bottom: 0.3em
      font-size: 16px
      color: #606266
      line-height: 1.15

</style>
