<template lang="pug">
  .editor-field__container
    Tinymce.editor-field(
      v-if="opened"
      v-model="currentValue"
      :height="field.height"
      :mini="field.mini"
      :textHeight="field.textHeight"
      :rows="field.rows"
      :formats="field.formats"
    )
    .editor-field__delayed-block(v-else)
      .editor-field__delayed-content(v-html="currentValue")
      el-button(size="mini" @click="opened = true") Изменить
    field-meta(ref="meta" element-name="символов" :len="clearValue.length" :min="field.min" :max="field.max" :errors="[]")

</template>
<script>
  import Tinymce from '@/components/Tinymce'
  import FieldMeta from '@/components/fields/FieldMeta'

  export default {
    name: 'EditorField',
    components: { FieldMeta, Tinymce },
    props: {
      field: { type: Object, default: () => ({}) },
      value: { type: [Number, String], default: null }
    },
    data() {
      return {
        currentValue: this.value,
        opened: !this.field.delayed
      }
    },
    watch: {
      currentValue: {
        deep: true,
        handler() {
          this.checkErrors()
          this.$emit('input', this.currentValue)
        }
      },
      value: {
        deep: true,
        handler() {
          this.currentValue = this.value
        }
      }
    },
    computed: {
      clearValue() {
        return (this.currentValue || '')
          .replace('&nbsp;', ' ')
          .replace(/(&amp;|&lt;|&gt;|&quot;|&apos;)/gmu, '_')
          .replace(/(<[^>]+>|\n)/gmu, '')
          .trim()
      },
      len() {
        return this.clearValue.length
      },
      lang() {
        return this.$store.getters.language
      }
    },
    methods: {
      checkErrors() {
        this.$refs.meta.check()
      }
    }
  }
</script>
<style lang="sass">
.editor-field
  .tox-statusbar__branding
    display: none
  &__container
    position: relative
    padding-bottom: 5px
  &__delayed-block
    display: flex
    width: 100%
    justify-content: space-between
    align-items: stretch
  &__delayed-content
    padding: 0 8px
  &__errors
    position: absolute
    left: 0
    bottom: -7px
    padding-left: 3px
    color: red
    line-height: 12px
    font-size: 9px
    cursor: default
  &__info
    line-height: 12px
    font-size: 9px
    position: absolute
    right: 0
    bottom: -7px
    padding-right: 3px
    color: #bababa
    cursor: default
</style>
