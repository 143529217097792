const imgHost = window.__data.env.IMG_HOST

export default {
  methods: {
    getUrl(image) {
      if (typeof image !== 'string') {
        return ''
      }
      if (image.substr(0, 4) === 'http' || image.substr(0, 2) === '//') {
        return image
      }
      image = image[0] === '/' ? image : `/${image}`
      return `${imgHost}${image}`
    }
  }
}
