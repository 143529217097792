 <template lang="pug">
  .tinymce-container.editor-container(:class="{fullscreen:fullscreen}" ref="container")
    textarea.tinymce-textarea(:id="tinymceId")
    el-dialog(title="Загркузка файла" :zIndex="3000" :visible.sync="upload.dialogVisible" width="40%")
      single-file-upload(v-model="upload.file" :field="{ horizontal: true, allow_links: true }")
      el-button(@click="handleUploadCancel") Отменить
      el-button(type="primary" @click="handleUploadConfirm") Выбрать
    //-
        <div class="editor-custom-btn-container">
          <editorImage color="#1890ff" class="editor-upload-btn" @successCBK="imageSuccessCBK" />
        </div>
</template>

<script>
import editorImage from './components/EditorImage'
import plugins from './plugins'
import miniPlugins from './mini-plugins'
import toolbar from './toolbar'
import miniToolbar from './mini-toolbar.js'

import SingleFileUpload from '../Upload/SingleFile'

export default {
  name: 'Tinymce',
  components: { SingleFileUpload, editorImage },
  props: {
    id: {
      type: String,
      default: function() {
        return 'vue-tinymce-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '')
      },
    },
    value: {
      type: String,
      default: '',
    },
    toolbar: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    menubar: {
      type: String,
      default: 'file edit insert view format table',
    },
    height: {
      type: Number,
      required: false,
      default: 360,
    },
    mini: { type: Boolean, default: false },
    textHeight: { type: Number, default: null },
    rows: { type: Number, default: null },
    formats: { type: Array, default: null }
  },
  data() {
    return {
      hasChange: false,
      hasInit: false,
      tinymceId: this.id,
      fullscreen: false,
      upload: {
        dialogVisible: false,
        file: null,
        promise: null,
        resolve: null,
        reject: null
      },
      languageTypeList: {
        'ru': 'ru'
        // 'en': 'en',
        // 'ru': 'ru_RU',
      },
    }
  },
  computed: {
    language() {
      return this.languageTypeList[this.$store.getters.language]
    },
    fullHeight() {
      const textHeight = this.rows ? this.rows * 22 : this.textHeight
      return this.mini ? textHeight + 36 + 39 : textHeight + 36 + 39 * 3 + 18
    }
  },
  watch: {
    value(val) {
      if (!this.hasChange && this.hasInit) {
        this.$nextTick(() =>
          window.tinymce.get(this.tinymceId).setContent(val || ''))
      }
    },
    language() {
      this.destroyTinymce()
      this.$nextTick(() => this.initTinymce())
    },
  },
  mounted() {
    this.initTinymceDefer()
  },
  activated() {
    this.initTinymceDefer()
  },
  deactivated() {
    this.destroyTinymce()
  },
  destroyed() {
    this.destroyTinymce()
  },
  methods: {
    initTinymceDefer() {
      if (window.tinymceLoaded) {
        this.initTinymce()
      } else {
        window.tinymceLoadCallbacks.push(this.initTinymce)
      }
    },
    initTinymce() {
      const _this = this
      window.tinymce.init({
        // content_style: 'div { margin: 10px; border: 5px solid red; padding: 3px; }',
        body_class: 'content',
        content_css: ['/static/tinymce/skins/content/default/content.min.css', '/css/editor.css'],

        // contextmenu: "link image imagetools table spellchecker",
        language: this.language,
        selector: `#${this.tinymceId}`,
        autoresize_bottom_margin: 20,
        min_height: 100,
        // height: this.textHeight || this.rows ? this.fullHeight : this.height,
        object_resizing: false,
        toolbar: this.toolbar.length > 0 ? this.toolbar : (this.mini ? miniToolbar : toolbar),
        menubar: this.mini ? false : this.menubar,
        statusbar: !this.mini,
        plugins: this.mini ? miniPlugins : plugins,
        end_container_on_empty_block: true,
        powerpaste_word_import: 'clean',
        code_dialog_height: 450,
        code_dialog_width: 1000,
        advlist_bullet_styles: 'square',
        advlist_number_styles: 'default',
        imagetools_cors_hosts: ['www.tinymce.com', 'codepen.io'],
        images_upload_handler: this.handleUpload,
        file_picker_types: 'file image media',
        file_picker_callback: this.handlePickFile,
        image_uploadtab: true,
        default_link_target: '_blank',
        link_title: false,

        table_class_list: [
          { title: 'Таблица по умолчанию', value: 'content__table-default' },
          { title: 'Таблица рейтингов', value: 'content__table-rates' },
          { title: 'Таблица с белым фоном', value: 'content__table-white' },
        ],
        table_default_attributes: {},
        table_default_styles: {
          // 'width': '100%'
          // 'border-collapse': 'collapse',
        },
        // table_responsive_width: false,

        nonbreaking_force_tab: true, // inserting nonbreaking space &nbsp; need Nonbreaking Space Plugin
        init_instance_callback: editor => {
          if (_this.value) {
            editor.setContent(_this.value)
          }
          _this.hasInit = true
          editor.on('NodeChange Change KeyUp SetContent', () => {
            this.hasChange = true
            this.$emit('input', editor.getContent())
          })
        },
        setup(editor) {
          editor.on('FullscreenStateChanged', (e) => {
            _this.fullscreen = e.state
          })
        },
        style_formats: this.formats || [
          { title: 'Синий текст', block: 'p', classes: 'blue' }
        ],
        style_formats_merge: true,
        visualblocks_default_state: true,
      })
      setTimeout(() => {
        this.checkHeight()
        setTimeout(() => {
          this.checkHeight()
        }, 500)
      }, 500)
    },
    checkHeight() {
      // let textHeight = this.rows ? this.rows * 22 : this.textHeight
      // textHeight += 32
      // const menuHeight = this.$refs.container.querySelector('.tox-menubar')?.clientHeight;
      // const toolbarHeight = this.$refs.container.querySelector('.tox-toolbar-overlord')?.clientHeight;
      // const statusHeight = this.$refs.container.querySelector('.tox-statusbar')?.clientHeight;
      // if (this.$refs.container && this.$refs.container.querySelector('.tox-edit-area') && parseInt(this.$refs.container.querySelector('.tox-edit-area').clientHeight) < 40) {
      //   console.log(this.$refs.container.querySelector('.tox-edit-area').clientHeight);
      //   this.$refs.container.querySelector('.tox-edit-area').style.height = '40px';
      // }
      // this.$refs.container.querySelector('.tox-edit-area').style.height = textHeight + 'px';
      // this.$refs.container.querySelector('.tox').style.height = (textHeight + menuHeight + toolbarHeight + statusHeight) + 'px';
    },
    destroyTinymce() {
      const tinymce = window.tinymce.get(this.tinymceId)
      if (this.fullscreen) {
        tinymce.execCommand('mceFullScreen')
      }

      if (tinymce) {
        tinymce.destroy()
      }
    },
    setContent(value) {
      window.tinymce.get(this.tinymceId).setContent(value)
    },
    getContent() {
      window.tinymce.get(this.tinymceId).getContent()
    },
    imageSuccessCBK(arr) {
      const _this = this
      arr.forEach(v => {
        window.tinymce.get(_this.tinymceId).insertContent(`<img class="wscnph" src="${v.url}" >`)
      })
    },
    // handleUpload(blobInfo, success, failure) {
    //   const formData = new FormData();
    //   formData.append('file', blobInfo.blob(), blobInfo.filename());
    //
    //   post('files/editor-upload', formData)
    //     .then(response => {
    //       console.log(response);
    //       success(response.path);
    //     })
    //     .catch(err => {
    //       failure('error');
    //     });
    // },
    handlePickFile(callback, value, meta) {
      const promise = this.handleUploadOpen(value, meta)

      promise.then(res => {
        callback(res.path)
      }).catch((error) => {
        console.error('Upload error: ', error)
        callback(null)
      })

      // Provide file and text for the link dialog
      // if (meta.filetype == 'file') {
      //   callback('mypage.html', {text: 'My text'});
      // }
      //
      // // Provide image and alt text for the image dialog
      // if (meta.filetype == 'image') {
      //   callback('myimage.jpg', {alt: 'My alt text'});
      // }
      //
      // // Provide alternative source and posted for the media dialog
      // if (meta.filetype == 'media') {
      //   callback('movie.mp4', {source2: 'alt.ogg', poster: 'image.jpg'});
      // }
    },
    handleUploadOpen(/* value, meta */) {
      this.upload.dialogVisible = true
      return new Promise((resolve, reject) => {
        this.upload.resolve = resolve
        this.upload.reject = reject
      })
    },
    handleUploadCancel() {
      this.upload.reject()
      this.upload.dialogVisible = false
    },
    handleUploadConfirm() {
      this.upload.resolve(this.upload.file)
      this.upload.dialogVisible = false
    }
  },
}
</script>

<style scoped>
.tinymce-container {
  position: relative;
  line-height: normal;
}
.tox-edit-area {
  min-height: 40px;
}
.tinymce-container>>>.mce-fullscreen {
  z-index: 10000;
}
.tinymce-textarea {
  visibility: hidden;
  z-index: -1;
}
.editor-custom-btn-container {
  position: absolute;
  right: 4px;
  top: 4px;
  /*z-index: 2005;*/
}
.fullscreen .editor-custom-btn-container {
  z-index: 10000;
  position: fixed;
}
.editor-upload-btn {
  display: inline-block;
}
</style>
