<template lang="pug">
el-dropdown.flexible-block-add(@command="handleClick")
  slot
    el-button.flexible-block-add__button(icon="el-icon-plus") Добавить блок
  el-dropdown-menu.flexible-block-add__menu(slot="dropdown")
    el-dropdown-item(v-for="block in blocks" :command="block.name" :key="block.name") {{ block.title }}
</template>
<script>
  export default {
    name: 'FlexibleBlockAdd',
    props: {
      blocks: { type: Array, default: () => ([]) }
    },
    methods: {
      handleClick(blockName) {
        this.$emit('add', blockName)
      }
    },
  }
</script>
<style lang="sass">
  $border-color: rgb(225, 225, 225)
</style>
