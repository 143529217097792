<template lang="pug">
  table.repeater-field__table
    template(v-if="!currentValue.length")
      tr.repeater-field__row
        td.repeater-field__field.repeater-field__field-action
          span.repeater-field__empty-text Пустой список
      tr.repeater-field__row
        td.repeater-field__field.repeater-field__field-action
          el-button.repeater-field__empty-plus-button(
            icon="el-icon-plus"
            @click.prevent="handleInsert(0)"
          ) Добавить

    draggable(
      tag="tbody"
      v-model="currentValue"
      v-bind="dragOptions"
      v-on="dragEvents"
    )
      tr.repeater-field__draggable-item.repeater-field__row(v-for="(row, rowIndex) in currentValue" :key="row._hash")
        td.repeater-field__field.repeater-field__field-index(:class="{ 'repeater-field__field-index_first': rowIndex === 0 }")
          .repeater-field__index {{ rowIndex + 1 }}
          .repeater-field__duplicate
            el-button.repeater-field__duplicate(icon="el-icon-copy-document" size="mini" circle @click.prevent="handleDuplicate(rowIndex)")
        td.repeater-field__field
          .repeater-field__block-row(v-if="!field.accordion || openList.includes(rowIndex)")
            .repeater-field__block-field(v-for="(rowField, rowFieldIndex) in field.fields" :key="rowField.name" :ref="`field__${row._hash}__${rowField.name}`" :style="fieldStyles[`field__${row._hash}__${rowField.name}`]")
              condition-field(
                :field="rowField"
                v-if="showReInitFields || !reInitFieldTypes.includes(rowField.type)"
                v-model="currentValue[rowIndex][rowField.name]"
                :ref="rowField.name"
                :allValues="currentValue[rowIndex]"
              )
            el-button(@click="openList.splice(openList.indexOf(rowIndex), 1)" v-if="field.accordion") Свернуть
          .repeater-field__block-row.repeater-field__block-row_accordioned(v-else)
            .repeater-field__accordion-title {{ renderAccordionTitle(currentValue[rowIndex]) }}
            el-button(@click="openList.push(rowIndex)") Развернуть

        td.repeater-field__field.repeater-field__field-action
          el-button.repeater-field__field-action-button.repeater-field__field-action-button-plus_top(
            icon="el-icon-plus"
            size="mini"
            circle
            @click.prevent="handleInsert(rowIndex - 1)"
            v-if="rowIndex === 0"
          )
          el-button.repeater-field__field-action-button.repeater-field__field-action-button-plus(
            icon="el-icon-plus"
            size="mini"
            circle
            @click.prevent="handleInsert(rowIndex)"
          )
          el-button.repeater-field__field-action-button.repeater-field__field-action-button-minus(
            icon="el-icon-minus"
            size="mini"
            circle
            @click.prevent="handleDelete(rowIndex)"
          )

        td.repeater-field__field.repeater-field__handle
          .repeater-field__handle-inner
            svg-icon(icon-class="move")
        // td.repeater-field__field.repeater-field__field-action
          .repeater-field__code(v-if="rowIndex === 0")
            el-button.repeater-field__code-button(icon="el-icon-c-scale-to-original" size="mini" circle @click.prevent="openCodeModal")
          el-button.repeater-field__field-action-button.repeater-field__field-action-button-up(
            icon="el-icon-arrow-up"
            size="mini"
            circle
            @click.prevent="handleUp(rowIndex)" :disabled="rowIndex === 0"
          )
          el-button.repeater-field__field-action-button.repeater-field__field-action-button-down(
            icon="el-icon-arrow-down"
            size="mini"
            circle
            @click.prevent="handleDown(rowIndex)" :disabled="rowIndex === currentValue.length - 1"
          )
</template>
<script>
  import List from './List'

  export default {
    name: 'BlockList',
    extends: List,
    data() {
      return {
      }
    },
    computed: {
    },
    methods: {
    },
  }
</script>
