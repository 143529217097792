import Layout from '@/layout'

const templates = {
  'Layout': Layout,
  'default': Layout,
  'options': () => import('@/views/content/OptionsPage'),
  'entity-list': () => import('@/views/content/EntityList'),
  'entity-edit': () => import('@/views/content/EntityEdit'),
  'entity-create': () => import('@/views/content/EntityCreate'),
  'dashboard': () => import('@/views/dashboard'),
  'users-list': () => import('@/views/users/List'),
  'users-edit': () => import('@/views/users/Profile'),
  'users-create': () => import('@/views/users/Create'),
  'change-my-password': () => import('@/views/users/ChangeMyPassword'),
  'logs-list': () => import('@/views/logs/List'),
  'permissions-list': () => import('@/views/permissions/List'),
  'roles-list': () => import('@/views/roles/List'),
  'in-development': () => import('@/views/content/InDevelopment'),
  'info': () => import('@/views/content/Info'),
  'panel': () => import('@/views/panel'),
  'leasing-calculator-requests': () => import('@/views/content/LeasingCalculatorRequests'),
  'actions': () => import('@/views/content/Actions'),
  'file-manager': () => import('@/views/file-manager/index')
}
/*
const templates = {
  'default': import('@/layout'),
  'options-page': import('@/views/content/OptionsPage'),
};
*/

const domains = window.__data.domains || []
// console.log(JSON.stringify(laraRoutes));

const addComponentImport = route => {
  // console.log(templates[route.component]);
  // route.component = (route.component && !['default', 'Layout'].includes(route.component) && Object.keys(templates).includes(route.component) ? () => templates[route.component] : templates.default);
  route.component = (route.component && !['default', 'Layout'].includes(route.component) && Object.keys(templates).includes(route.component) ? templates[route.component] : templates.default)
}

const checkRoutesArrayForComponents = routesArr => {
  if (Array.isArray(routesArr)) {
    routesArr.forEach(route => {
      addComponentImport(route)
      if (Array.isArray(route.children)) {
        checkRoutesArrayForComponents(route.children)
      }
    })
  }
}

const floatRoutes = domains.reduce((arr, el) => ([...arr, ...el.menu]), [])

checkRoutesArrayForComponents(floatRoutes)

// console.log(laraRoutes);

export default floatRoutes

