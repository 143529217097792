<template lang="pug">
  .dataset
    .dataset__head
      el-tooltip(effect="light" content="Развернуть" placement="top" v-if="field.closed && !opened" :open-delay="1000")
        .dataset__closed-row(@click="opened = true")
          .dataset__title {{ closedTitle }}
      .dataset__opened-row(v-else)
        .dataset__permission(if="!fetching && field.show_permission && currentValue.id")
          | Разрешение:&nbsp;
          code {{ permission }}
        el-button.dataset__button.dataset__button_opened(@click="opened = false") свернуть
    .dataset__body(v-loading="fetching" v-if="!field.closed || opened")
      .dataset__fields(v-if="!fetching" :class="{ 'dataset__fields_margin': !field.show_permission }")
        condition-field(v-for="fieldItem in field.fields" :field="fieldItem" v-model="dataValue[fieldItem.name]" :key="fieldItem.name" :allValues="dataValue" @allChange="currentValue = $event")
      .dataset__save-btn(v-if="!fetching")
          .dataset__save-btn-row(v-loading="loading")
            a.dataset__save-link(v-if="viewUrl" :href="viewUrl" target="_blank")
              el-button(type="primary" @click="handleView()" :disabled="loading") Смотреть на сайте
            template(v-if="hasPermissions")
              el-button(type="success" @click="handlePreview()" :disabled="loading || !savable" v-if="viewUrl") Предпросмотр
              el-button(type="success" @click="handleSave()" :disabled="loading || !savable") Сохранить
            el-button(v-else type="info" disabled) Нет прав для изменения

    preview-dialog(ref="previewDialog" :link="viewUrl")
</template>
<script>
  import ConditionField from '@/components/fields/ConditionField'
  import PreviewDialog from '@/components/PagePartials/PreviewDialog'
  import { notifyRequest, notifyErrorRequest } from '@/utils/api'

  export default {
    name: 'DatasetField',
    props: {
      field: { type: Object, default: () => ({}) },
      value: { type: Object, default: null }
    },
    components: {
      PreviewDialog,
      ConditionField
    },
    data() {
      return {
        errors: [],
        currentValue: { id: null },
        dataValue: {},
        dataValueCache: '{}',
        loading: false,
        fetching: true,
        opened: false
      }
    },
    async created() {
      const id = this.value && this.value.id ? this.value.id : null
      const data = this.value && this.value.data ? this.value.data : null
      this.currentValue = { id: id }
      this.dataValue = data && typeof data === 'object' ? this.copy(data) : null
      this.fetching = false
      if (!this.dataValue) {
        await this.loadData()
      }
      if (!this.dataValue) {
        this.dataValue = JSON.parse('{}')
      }
      this.updateCache()
    },
    watch: {
      currentValue: {
        deep: true,
        handler() {
          this.$emit('input', this.currentValue)
        }
      },
      value: {
        deep: true,
        handler() {
          this.currentValue.id = this.value && this.value.id ? this.value.id : null
          this.loadData()
        }
      }
    },
    computed: {
      viewUrl() {
        return this.$store.state.page.viewUrl
      },
      hasPermissions() {
        return this.$store.state.user.permissions.includes(this.permission) || this.$store.state.user.permissions.includes(`update ${this.$store.state.page.page}`)
      },
      permission() {
        return this.currentValue && this.currentValue.id ? `update dataset_${this.currentValue.id}` : null
      },
      savable() {
        return this.dataValue && JSON.stringify(this.dataValue) !== this.dataValueCache
      },
      lang() {
        return this.$store.getters.language
      },
      closedTitle() {
        return this.field.closed_title_field
          .split('.')
          .reduce((item, property) => (item || {})[property], this.dataValue) || 'Заголовок не задан'
      }
    },
    methods: {
      copy(obj) {
        return JSON.parse(JSON.stringify(obj))
      },
      getDuplicateData() {
        return this.copy({ data: this.dataValue })
      },
      async loadData() {
        if (!this.currentValue.id) {
          return
        }
        if (this.dataValue) {
          return
        }
        if (this.fetching) {
          return
        }
        this.fetching = true
        if (this.currentValue.id) {
          this.dataValue = await this.fetch()
        }
        await this.$nextTick()
        this.updateCache()
        this.fetching = false
      },
      updateCache() {
        this.dataValueCache = JSON.stringify(this.dataValue)
      },
      async handleSave() {
        if (this.loading) {
          return
        }
        this.loading = true
        if (this.currentValue.id) {
          const success = await this.save()
          if (success) {
            this.updateCache()
          }
        } else {
          const success = await this.create()
        }
        this.loading = false
      },
      async handleView() {
        window.open(this.viewUrl, '_blank')
      },
      async handlePreview() {
        if (this.$refs.previewDialog.isLoading()) {
          return
        }
        this.$refs.previewDialog.showLoading()
        const responseData = await notifyErrorRequest({
          url: `datasets/${this.currentValue.id}/preview`,
          method: 'put',
          data: {
            value: this.dataValue
          },
        }, 'Ошибка при проверке данных')
        if (responseData && responseData.data && responseData.data.version) {
          this.$refs.previewDialog.open(responseData.data.version)
        } else {
          this.$refs.previewDialog.stopLoading()
          this.$refs.previewDialog.hide()
        }
        /*
        this.$confirm('Данный функционал находится в разработке', 'Предпросмотр несохраненной страницы', {
          confirmButtonText: 'ОК'
          // type: 'warning'
        })
        */
      },
      async fetch() {
        const responseData = await notifyErrorRequest({
          url: 'datasets/' + this.currentValue.id,
          method: 'get'
        })
        return responseData.value
      },
      async create() {
        const responseData = await notifyRequest({
          url: 'datasets',
          method: 'post',
          data: {
            type: this.field.dataset_type,
            page: this.$store.state.page.page,
            value: this.dataValue
          }
        }, 'Датасет успешно создан')
        this.currentValue.id = responseData.id
        return !!responseData.success || (!responseData.error && !responseData.errors)
      },
      async save() {
        const response = await notifyRequest({
          url: 'datasets/' + this.currentValue.id,
          method: 'put',
          data: {
            value: this.dataValue
          }
        }, 'Датасет успешно сохранен')
        return !!response.success || (!response.error && !response.errors)
      }
    },
  }
</script>
<style rel="stylesheet/sass" lang="sass">
.dataset
  position: relative
  border: 1px solid lightgrey
  font-size: 14px
  &__title
    padding: 5px 15px
  &__body
    padding: 10px 15px 15px
  &__opened-row
    display: flex
    flex-flow: row nowrap
    width: 100%
    justify-content: space-between
    padding: 0
  &__closed-row
    width: 100%
    min-height: 69px
    display: flex
    align-items: center
    cursor: pointer
    transition: 0.5s
    &:hover
      background-color: #e8f4ff
      color: #1890ff
  &__button
    &_opened
      border-top-width: 0
      border-right-width: 0
      line-height: 20px
      padding: 3px 10px
  &__permission
    padding: 8px 15px 3px 15px
  &__row
    display: flex
    flex-flow: column nowrap
  &__fields
    width: 100%
    &_margin
      margin-bottom: 15px
  &__save-btn
    margin-top: 10px
    width: 100%
    &-row
      width: 100%
      display: flex
      flex-flow: row nowrap
      .el-button:not(:first-child)
        margin-left: 6px
      .el-button:last-child
        flex-grow: 1

</style>
