<script>
export default {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  render(h, context) {
    const { icon, title } = context.props
    const vnodes = []

    if (icon) {
      if (icon.substr(0, 7) === 'el-icon') {
        const classAttr = `${icon} sidebar-el-icon`
        vnodes.push(<i class={classAttr}/>)
      } else {
        vnodes.push(<svg-icon icon-class={icon}/>)
      }
    }

    if (title) {
      vnodes.push(<span slot='title'>{(title)}</span>)
    }
    return vnodes
  },
}
</script>
<style lang="sass">
.el-menu-item .sidebar-el-icon[class^="el-icon-"], .el-submenu .sidebar-el-icon[class^="el-icon-"]
  width: 1em
  height: 1em
  margin-right: 12px
  margin-left: -2px

.sidebar-el-icon
  &:before
    color: rgb(191, 203, 217)

#app .hideSidebar
  .submenu-title-noDropdown
    display: block
    min-height: 40px
  .sidebar-el-icon
    width: 100%
    height: 100%
    line-height: 40px
    margin: 0
  .el-submenu
    height: 40px
    line-height: 40px
    &__title
      height: 40px
      line-height: 40px
</style>
