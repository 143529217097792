<template lang="pug">
  .link-tree__container(v-loading="loading")
    el-tree(
      :data="data"
      :props="defaultProps"
      @node-click="handleNodeClick"
      @check-change="handleCheck"
      v-if="data && !loading"
      show-checkbox
      highlight-current
      node-key="nodeId"
      ref="tree"
    )
      // :default-expanded-keys="defaultExpanded"
      // :default-checked-keys="defaultChecked"
      template(slot-scope="{node, data}")
        .link-tree__item(:class="{ 'file-tree__item_active': data.type === 'file' && files.includes(data.file.id) }")
          .link-tree__item-name {{ data.title }}
          .link-tree__item-link {{ data.path }}
          .link-tree__item-right
            .link-tree__item-date {{ formatDate(data.created_at) }}
            a.link-tree__item-download(:href="data.url" download target="_blank") скачать
</template>

<script>
  import FileTree from './FileTree'
  import { notifyErrorRequest } from '@/utils/api'

  export default {
    name: 'FileBrowser',
    components: { FileTree },
    props: {
      base: {
        type: String,
        default: '/'
      },
      files: {
        type: Array,
        default: () => []
      },
      limit: {
        type: Number,
        default: 1000
      }
    },
    data() {
      return {
        dialogVisible: false,
        loading: true,
        data: null,
        defaultProps: {
          children: 'children',
          label: 'label'
        }
      }
    },
    async created() {
      if (!this.data) {
        this.loading = true
        this.data = await this.fetch()
        console.log(this.data)
        this.loading = false
      }
    },
    computed: {
      defaultExpanded() {
        if (this.files && this.files.length) {
          const files = this.findExpanded(this.data)
          if (files.length) {
            return files
          }
        }
        return this.findByBase(this.data[0].children)
      },
      defaultChecked() {
        if (this.files && this.files.length) {
          const files = this.findExpanded(this.data)
          if (files.length) {
            return files
          }
        }
        return []
      },
      tree() {
        return this.$refs.tree
      }
    },
    watch: {
    },
    methods: {
      findExpanded(arr) {
        return arr.reduce((res, el) => {
          /* if (true || el.type === 'file') {
            if (this.files.includes(el.file.id)) {
              res.push(el.nodeId)
            }
          } else {
            res = [...res, ...this.findExpanded(el.children)]
          } */
          res = [...res, ...this.findExpanded(el.children)]
          return res
        }, [])
      },
      findByBase(arr) {
        return arr.reduce((res, el) => {
          /* if (true || el.type === 'file') {
            if (el.file.model === this.base) {
              // console.log(el);
              res.push(el.nodeId)
            }
          } else {
            res = [...res, ...this.findByBase(el.children)]
          } */
          res = [...res, ...this.findByBase(el.children)]
          return res
        }, [])
      },
      async fetch() {
        const domain = this.$store.state.app.domain
        const data = await notifyErrorRequest({
          url: `links/${domain}`,
          method: 'get'
        })
        return data.data
      },
      handleNodeClick(/* data */) {
        // console.log(data);
      },
      handleCheck(/* data, tree, par3 */) {
        let checkedKeys = this.tree.getCheckedKeys()
        let checkedNodes = this.tree.getCheckedNodes()
        checkedNodes = checkedNodes.filter(el => !el.children || !el.children.length)
        checkedKeys = checkedNodes.map(el => el.nodeId)
        if (checkedKeys.length > this.limit) {
          this.tree.setCheckedKeys(checkedKeys.slice(checkedKeys.length - this.limit))
        } else {
          this.$emit('choose', checkedNodes)
        }
      },
      formatDate(date) {
        const ymd = date.split('T')[0]
        const time = date.split('T')[1]
        return `${ymd} ${time.split('.')[0]}`
      }
    },
  }
</script>
<style rel="stylesheet/sass" lang="sass">
  @import "@/assets/sass/mixin.scss"
  .link-tree
    &__item
      height: 100%
      width: 100%
      display: flex
      justify-content: space-between
      align-items: center
      &_active
        background-color: lightskyblue
      &-name
        line-height: 24px
        height: 24px
      &-right
        display: flex
      &-date
        line-height: 24px
        height: 24px
        margin-right: 15px
      &-download
        display: block
        border-bottom: 1px solid black
        transition: 0.3s
        line-height: 24px
        height: 24px
        &:hover
          border-bottom: 1px solid white
</style>
