<template lang="pug">
  .relation-has-many-field
    .relation-has-many-field__row
      .relation-has-many-field__col
        .relation-has-many-field__title {{ field.leftColumnTitle || 'Доступные элементы (перенесите вправо для выбор)' }}
        draggable(v-model="listAll" v-bind="dragOptions" v-on="dragActions").relation-has-many-field__list
          transition-group(type="transition" tag="div" :name="!drag ? 'relation-has-many-field__transition' : null").relation-has-many-field__list-transition
            .relation-has-many-field__item(v-for="id in listAll" :key="id")
              .relation-has-many-field__item-title {{ titles.get(id) }}

      .relation-has-many-field__col
        .relation-has-many-field__title {{ field.leftColumnTitle || 'Выбранные элементы' }}
        draggable(v-model="listChosen" v-bind="dragOptions" v-on="dragActions").relation-has-many-field__list
          transition-group(type="transition" tag="div" :name="!drag ? 'relation-has-many-field__transition' : null").relation-has-many-field__list-transition
            .relation-has-many-field__item(v-for="id in listChosen" :key="id")
              .relation-has-many-field__item-title {{ titles.get(id) }}

    field-meta(ref="meta" element-name="элементов" :len="listChosen.length" :min="field.min" :max="field.max" :errors="[]")
</template>
<script>

  import Draggable from 'vuedraggable'
  import FieldMeta from '@/components/fields/FieldMeta'

  export default {
    name: 'RelationHasManyField',
    components: {
      FieldMeta,
      Draggable
    },
    props: {
      field: { type: Object, default: () => ({}) },
      value: { type: Array, default: null }
    },
    data() {
      return {
        drag: false,
        listAll: [],
        listChosen: [],
        errors: []
      }
    },
    created() {
      this.listAll = this.field.options.map(el => el.id)
      this.listChosen = this.value.filter(id => this.listAll.includes(id))
      this.listAll = this.listAll.filter(id => !this.listChosen.includes(id))
    },
    computed: {
      id() {
        return this._uid
      },
      group() {
        return `relation-has-many-field__draggable-group-${this.id}`
      },
      titles() {
        return new Map(this.field.options.map(el => [el.id, this.makeTitle(el.title)]))
      },
      dragOptions() {
        return {
          animation: 200,
          tag: 'div',
          group: this.group,
          disabled: false,
          ghostClass: 'relation-has-many-field__ghost',
          delay: 0,
          sort: false
        }
      },
      dragActions() {
        return {
          change: this.onChange,
          start: () => this.drag = true,
          end: () => this.drag = false
        }
      }
    },
    methods: {
      onChange() {
        this.checkErrors()
        this.$emit('input', [...this.listChosen])
      },
      checkErrors() {
        this.$refs.meta.check()
      },
      makeTitle(str) {
        return str.replace(/<\/?[^>]+(>|$)/g, ' ').replace(/&nbsp;/g, ' ').replace(/ {2}/g, ' ')
      }
    },
  }
</script>
<style lang="sass">
  // .no-move
    transition: transform 0s
  .relation-has-many-field
    &__row
      display: flex
      flex-flow: row wrap
      align-items: stretch
    &__transition
      &-move
        transition: transform 0.5s
    &__col
      width: calc(50% - 5px)
      min-width: 250px
      display: flex
      flex-flow: column nowrap
      &:not(:last-child)
        margin-right: 10px
    &__title
      line-height: 1.5em
      padding: 10px 5px 5px 2px
      font-weight: 700
      font-size: 12px
      color: #585a5e
    &__list
      flex: 1
      border: 1px dashed #cacacb
      padding: 5px
      max-height: 400px
      background-color: #eff0f0
      overflow-y: auto
    &__list-transition
      min-height: 100%
    &__item
      padding: 5px 10px
      border: 1px solid #cacacb
      background-color: white
      cursor: move
      line-height: 1.4em
      font-size: 14px
      &:not(:last-child)
        margin-bottom: 5px
    &__ghost
      opacity: 0.5
</style>
