<template lang="pug">
  .upload-file-multiple(
    :class="{'upload-file-multiple_empty': empty}"
  )
    el-upload(
      :multiple="true"
      :before-upload="handleBeforeUpload"
      :on-success="handleImageSuccess"
      :on-remove="handleRemove"
      :on-preview="handlePreview"
      drag
      :file-list="fileList"
      :with-credentials="true"
      :data="data"
      :headers="headers"
      :limit="field.limit"
      :action="action"
    )
      .upload-file-multiple__inner
        i.el-icon-upload.upload-file-multiple__icon
        .el-upload__text.upload-file-multiple__text
          | Перенесите файл сюда или
          em &nbsp;Нажмите для загрузки
    file-browser(
      :base="$route.meta.model.replace(/__/g, '/').replace('options/', '')"
      :files="value"
      :limit="this.field.limit"
      @input="emitInput($event.map(el => el.path))"
    )
</template>

<script>
  import hasUrlGetter from '@/utils/has-url-getter'
  import FileBrowser from '@/components/Browser/FileBrowser'
  export default {
    name: 'MultipleFileUpload',
    mixins: [hasUrlGetter],
    components: { FileBrowser },
    props: {
      value: {
        type: Array,
        default: () => ([]),
      },
      field: {
        type: Object,
        default: () => ({})
      },
    },
    data() {
      return {
        tempUrl: '',
        additionalData: {},
        dialogImageUrl: '',
        dialogVisible: false,
        fileList: this.makeFileList(),
        changed: 0,
        empty: !this.value.length
      }
    },
    watch: {
      value: {
        deep: true,
        handler() {
          this.empty = !this.value.length
          if (!this.changed) {
            this.changed = true
            this.fileList = this.makeFileList()
          }
        }
      }
    },
    computed: {
      headers() {
        return {
          'Authorization': 'Bearer ' + this.$store.state.user.token
        }
      },
      data() {
        return {
          model: this.$route.meta.model,
          model_id: 0 // TODO: make work loading with model_id. this.$route.params.id
        }
      },
      action() {
        return process.env.VUE_APP_API_BASE + '/files/upload'
      },
    },
    methods: {
      makeValue(fileList) {
        return fileList.map(el => {
          if (el.response) {
            return el.response.path
          }
          return el.url
        })
      },
      makeFileList() {
        return (this.value || []).map(el => ({ name: this.getName(el), url: el }))
      },
      getName(str) {
        const splitArr = str.split('/')
        return splitArr[splitArr.length - 1]
      },
      handleRemove(file, fileList) {
        this.emitInput(this.makeValue(fileList))
      },
      emitInput(val) {
        this.$emit('input', val)
      },
      handleBeforeUpload() {
        this.empty = false
      },
      handleImageSuccess(response, file, fileList) {
        this.emitInput(this.makeValue(fileList))
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url
        this.dialogVisible = true
      },
      handlePreview(file) {
        window.open(this.getUrl(file.url), '_blank')
      }
    },
  }
</script>

<style rel="stylesheet/scss" lang="sass">
@import "@/assets/sass/mixin.scss"
.upload-file-multiple
  z-index: 2
  &__inner
    width: 100%
    height: 100%
    display: flex
    flex-flow: row nowrap
  & &__icon
    line-height: 48px
    height: 50px
    width: 50px
    margin: 0 0 0 5px
    font-size: 44px
  &__text
    margin-left: 12px
    line-height: 50px
  .el-icon-document:before, .el-icon-circle-close:before, .el-icon-upload-success:before
    line-height: 28px
    font-size: 18px
    height: 28px
  .el-icon-close
    top: 0
    line-height: 28px
    font-size: 18px
    height: 28px
  .el-icon-close-tip
    line-height: 28px
    color: gray

  &_empty
    .el-upload-list
      display: none
  .el-upload-list
    height: 30px
    position: relative
    margin-top: 8px
    &__item
      border: 1px dashed #d9d9d9
      height: 30px
      font-size: 15px
      position: absolute
      top: 0
      left: 0
      &:first-child
        margin-top: 0
  .el-upload
    width: 100%
    display: block
  .el-list-enter, .el-list-leave-active
    opacity: 0
    transform: translateX(30px)
  .el-list-leave-active
    opacity: 0
  .el-upload-dragger
    width: 100%
    height: 50px
    .el-icon-upload
      display: block
      left: 10px

</style>
