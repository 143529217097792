<template lang="pug">

  .upload-img-multiple(:class="{\
    'upload-img-multiple_horizontal': field.direction === 'horizontal',\
    'upload-img-multiple_vertical': field.direction === 'vertical',\
    'upload-img-multiple_contain': field.contain\
  }")
    el-upload(
      :multiple="true"
      :on-success="handleImageSuccess"
      :on-remove="handleRemove"
      :before-remove="beforeRemove"
      list-type="picture-card"
      drag
      :file-list="fileList"
      :with-credentials="true"
      :data="data"
      :headers="headers"
      :limit="field.limit || 1000"
      :action="action"
    )
      i.el-icon-upload
      .el-upload__text
        | Перенесите файл сюда или
        em Нажмите для загрузки
    file-browser(
      :base="$route.meta.model.replace(/__/g, '/').replace('options/', '')"
      :files="value"
      :limit="this.field.limit"
      @input="emitInput($event)"
    )

  //-
    <div class="upload-container-multiple">
      <el-upload :multiple="true" :on-success="handleImageSuccess" :on-remove="handleRemove"
        list-type="picture-card"
        drag :file-list="fileList"
        action="/api/admin/upload">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">Перенесите файл сюда или <em>Нажмите для загрузки</em></div>
        <!--<i class="el-icon-plus"></i>-->
        <!--<div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div>
        <i class="el-icon-upload"/> :show-file-list="false" class="image-uploader" :data="additionalData"
        <div class="el-upload__text">Перенесите файл сюда или <em>Нажмите для загрузки</em></div>-->
      </el-upload>
      <!--<el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>-->
      <!--<div class="image-preview image-app-preview" v-for="imageUrl, index in value" :key="index">
        <div v-show="imageUrl.length>1" class="image-preview-wrapper">
          <img :src="imageUrl">
          <div class="image-preview-action">
            <i class="el-icon-delete" @click="rmImage(index)" />
          </div>
        </div>
      </div>
      <div class="image-preview" v-for="imageUrl, index in value" :key="index">
        <div v-show="imageUrl.length>1" class="image-preview-wrapper">
          <img :src="imageUrl">
          <div class="image-preview-action">
            <i class="el-icon-delete" @click="rmImage(index)" />
          </div>
        </div>
      </div>-->
    </div>
</template>

<script>
import hasUrlGetter from '@/utils/has-url-getter'
import FileBrowser from '@/components/Browser/FileBrowser'
export default {
  name: 'MultipleImageUpload',
  mixins: [hasUrlGetter],
  components: { FileBrowser },
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
    field: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      tempUrl: '',
      additionalData: {},
      dialogImageUrl: '',
      dialogVisible: false,
      changed: 0
    }
  },
  watch: {
    value: {
      deep: true,
      handler() {
        /* if (!this.changed) {
          this.changed = true;
          this.fileList = this.makeFileList();
        }*/
      }
    }
  },
  computed: {
    headers() {
      return {
        'Authorization': 'Bearer ' + this.$store.state.user.token
      }
    },
    data() {
      return {
        model: this.$route.meta.model,
        model_id: 0 // TODO: make work loading with model_id. this.$route.params.id
      }
    },
    action() {
      return process.env.VUE_APP_API_BASE + '/files/upload'
    },
    fileList() {
      return (this.value || []).map(el => ({ name: el.title, url: this.prepareUrl(this.getUrl(el.path)) }))
    }
  },
  methods: {
    prepareUrl(url) {
      return this.$imaginary(url, this.field.contain ? 'fit' : 'crop', { width: 156, height: 136 })
    },
    handleRemove(/* file, fileList */) {
      // console.log(file, fileList);
      // this.emitInput(this.makeValue(fileList));
    },
    emitInput(val) {
      this.$emit('input', val)
    },
    handleImageSuccess(response, file, fileList) {
      console.log(response, file, fileList, JSON.stringify(fileList))
      this.emitInput([...this.value, response.data])
    },
    handleImageChange(...args) {
      console.log('change', args[0].currentTarget)
      // this.emitInput([...this.value, response.data]);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    beforeRemove(file, fileList) {
      fileList.forEach((el, index) => {
        if (el.uid === file.uid) {
          this.removeByIndex(index)
        }
      })
    },
    removeByIndex(index) {
      const val = [...this.value]
      val.splice(index, 1)
      this.emitInput(val)
    }
  },
}
</script>

<style rel="stylesheet/scss" lang="sass">
@import "@/assets/sass/mixin.scss"
.upload-img-multiple
  width: 100%
  position: relative
  & .el-upload-dragger
    width: 100%
    height: 100%
  & .el-upload-list__item-thumbnail
    object-fit: cover
  &_contain
    & .el-upload-list__item-thumbnail
      object-fit: contain

  //
      @include clearfix;
    .image-uploader {
      width: 35%;
      float: left;
    }
    .image-preview {
      width: 200px;
      height: 200px;
      position: relative;
      border: 1px dashed #d9d9d9;
      float: left;
      margin-left: 50px;
      .image-preview-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .image-preview-action {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        cursor: default;
        text-align: center;
        color: #fff;
        opacity: 0;
        font-size: 20px;
        background-color: rgba(0, 0, 0, .5);
        transition: opacity .3s;
        cursor: pointer;
        text-align: center;
        line-height: 200px;
        .el-icon-delete {
          font-size: 36px;
        }
      }
      &:hover {
        .image-preview-action {
          opacity: 1;
        }
      }
    }
    .image-app-preview {
      width: 320px;
      height: 180px;
      position: relative;
      border: 1px dashed #d9d9d9;
      float: left;
      margin-left: 50px;
      .app-fake-conver {
        height: 44px;
        position: absolute;
        width: 100%; // background: rgba(0, 0, 0, .1);
        text-align: center;
        line-height: 64px;
        color: #fff;
      }
    }}

</style>
