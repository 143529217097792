import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookies from 'js-cookie'
import elementRuLocale from 'element-ui/lib/locale/lang/ru-RU'// element-ui lang
// import elementEnLocale from 'element-ui/lib/locale/lang/en'; // element-ui lang
import ruLocale from './ru'
// import enLocale from './en'

Vue.use(VueI18n)

const messages = {
  ru: {
    ...ruLocale,
    ...elementRuLocale,
  },
  // en: {
  //   ...enLocale,
  //   ...elementEnLocale,
  // }
}

export function getLanguage() {
  const chooseLanguage = Cookies.get('language')
  if (chooseLanguage) {
    return chooseLanguage
  }

  // if has not choose language
  const language = (navigator.language || navigator.browserLanguage).toLowerCase()
  const locales = Object.keys(messages)
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale
    }
  }
  return 'ru'
}
const i18n = new VueI18n({
  // set locale
  // options: en | vi | zh
  locale: getLanguage(),
  silentTranslationWarn: /^route\..*/,
  // set locale messages
  messages,
})

VueI18n.prototype.getChoiceIndex = function(choice, choicesLength) {
  // this === VueI18n instance, so the locale property also exists here
  if (this.locale !== 'ru') {
    // proceed to the default implementation
  }

  if (choice === 0) {
    return 0
  }

  const teen = choice > 10 && choice < 20
  const endsWithOne = choice % 10 === 1

  if (!teen && endsWithOne) {
    return 1
  }

  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2
  }

  return (choicesLength < 4) ? 2 : 3
}

export default i18n
