import defaultSettings from '@/settings'
const { showSettings, tagsView, fixedHeader, sidebarLogo, theme, emailAuthentication, securitySettings } = defaultSettings

const state = {
  theme: theme,
  showSettings: showSettings,
  tagsView: tagsView,
  fixedHeader: fixedHeader,
  sidebarLogo: sidebarLogo,
  emailAuthentication: emailAuthentication,
  security: securitySettings
}

const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    if (Object.hasOwnProperty.call(state, key)) {
      state[key] = value
    }
  },
}

const actions = {
  changeSetting({ commit }, data) {
    commit('CHANGE_SETTING', data)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}

