import { render, staticRenderFns } from "./EditorField.vue?vue&type=template&id=2f8ffb75&lang=pug&"
import script from "./EditorField.vue?vue&type=script&lang=js&"
export * from "./EditorField.vue?vue&type=script&lang=js&"
import style0 from "./EditorField.vue?vue&type=style&index=0&id=2f8ffb75&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports