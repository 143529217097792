<template lang="pug">
  el-select(v-model="currentValue" :placeholder="field.placeholder || 'Выберите'")
    el-option(
      v-if="field.nullable"
      :key="-1"
      label="Отсутствует"
      :value="null"
    )
    el-option(
      v-for="(label, key) in field.options"
      :key="label.id"
      :label="label.title"
      :value="label.id"
    )
</template>
<script>

  export default {
    name: 'RelationBelongsField',
    components: {},
    props: {
      field: { type: Object, default: () => ({}) },
      value: { type: [Number, String], default: null }
    },
    data() {
      return {
        currentValue: this.value
      }
    },
    watch: {
      currentValue: {
        deep: true,
        handler() {
          this.$emit('input', this.currentValue)
        }
      },
      value: {
        deep: true,
        handler() {
          this.currentValue = this.value
        }
      }
    },
    computed: {
    },
    methods: {
    },
  }
</script>
<style lang="sass">
</style>
