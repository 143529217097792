<template lang="pug">
  .group-field
    // h3.group-field__title {{ field.title }}
    .group-field__subfield(v-for="(rowField, rowFieldIndex) in field.fields" :index="rowFieldIndex")
      condition-field(:field="rowField" v-model="currentValue[rowField.name]" :allValues="{ ...currentValue, ...allValues }")
</template>
<script>
  import ConditionField from '@/components/fields/ConditionField'

  const wait = async(ms) => {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  export default {
    name: 'GroupField',
    componentName: 'GroupField',
    props: {
      field: { type: Object, default: () => ({}) },
      value: { type: Object, default: null },
      allValues: { type: Object, default: () => ({}) }
    },
    components: {
      ConditionField
    },
    data() {
      return {
        currentValue: typeof this.value === 'object' && !Array.isArray(this.value) ? this.value : {}
      }
    },
    created() {
    },
    methods: {
      getNameWithoutNamespaces(name) {
        return name.split('__')[name.split('__').length - 1]
      }
    },
    computed: {
      allValuesWithoutNamespaces() {
        const obj = {}
        for (const i in this.allValues) {
          const name = this.getNameWithoutNamespaces(i)
          obj[name] = this.allValues[i]
        }
        return obj
      }
    },
    watch: {
      currentValue: {
        deep: true,
        handler() {
          this.$emit('input', this.currentValue)
        }
      },
      value: {
        deep: true,
        handler() {
          this.currentValue = this.value
        }
      }
    }
  }
</script>
<style lang="sass">
  .condition-field
    &__wide
      &-title
        font-size: 16px
        color: #606266

</style>
