<template lang="pug">
  .text-field
    .text-field__row
      el-input.text-field__input(
        ref="input"
        v-model="currentValue"
        :rows="field.rows"
        :type="field.input_type"
        :required="field.required"
        :autosize="field.autosize"
        :placeholder="field.placeholder"
        :class="{ 'text-field_invalid': this.errors.length > 0 }"
      )
      el-tooltip(content="Вставить неразрывной пробел в конец строки" effect="dark" :open-delay="1000" placement="top" v-if="!field.disableInsertSpace")
        el-button(@click="insertSpace").text-field__button
          // icon="el-icon-edit"
          svg-icon(icon-class="space")
    // .text-field__insert-space(@click="insertSpace") вставить неразрывной пробел// @blur='onBlur'
    field-meta(ref="meta" element-name="символов" :len="this.clearValue.length" :min="field.min" :max="field.max" :errors="[]")
</template>
<script>

  import FieldMeta from '@/components/fields/FieldMeta'
  export default {
    name: 'TextField',
    components: { FieldMeta },
    props: {
      field: { type: Object, default: () => ({}) },
      value: { type: [Number, String], default: null }
    },
    data() {
      let value = ''
      if (typeof this.value === 'number') {
        value = '' + this.value
      } else if (typeof this.value === 'string') {
        value = this.value
      } else if (typeof this.value === 'object' && this.value.toString) {
        value = this.value.toString()
      }
      return {
        errors: [],
        currentValue: value
      }
    },
    watch: {
      currentValue: {
        deep: true,
        handler() {
          this.$emit('input', this.currentValue)
          this.checkErrors()
        }
      },
      value: {
        deep: true,
        handler() {
          if (this.value !== this.currentValue) {
            this.currentValue = this.value
          }
        }
      }
    },
    computed: {
      clearValue() {
        return (this.currentValue || '')
          .replace('&nbsp;', ' ')
          .replace(/(&amp;|&lt;|&gt;|&quot;|&apos;)/gmu, '_')
          .replace(/(<[^>]+>|\n)/gmu, '')
          .trim()
      },
      lang() {
        return this.$store.getters.language
      }
    },
    methods: {
      onBlur() {
        this.checkErrors()
      },
      checkErrors() {
        this.$refs.meta.check()
      },
      insertSpace() {
        // non-breaking space in string
        this.currentValue += ' '
        this.$refs.input.focus()
      }
    },
  }
</script>
<style rel="stylesheet/sass" lang="sass">
.text-field
  position: relative
  min-height: 100%
  padding-bottom: 3px
  &__insert-space
    position: absolute
    top: 2px
    right: 5px
    cursor: pointer
    color: #606060
    font-size: 9px
    line-height: 9px
    opacity: 0.2
    transition: opacity 0.4s
    text-decoration: underline
    font-weight: 700
    &:hover
      opacity: 0.5
  &__row
    display: flex
    flex-flow: row nowrap
  &__button
    margin-left: 3px
    padding: 5px 6px
  // &__button
    padding: 5px 3px
    border-radius: 5px
    margin-left: 3px
    background-color: #606060
    transition: opacity 0.4s
    line-height: 40px
    box-sizing: border-box
    height: 40px
    &:hover
      opacity: 0.5

  &__input
    min-height: 100%
  &__bottom
    width: 100%
    display: flex
    justify-content: space-between
  &__errors
    padding-left: 3px
    color: red
    line-height: 12px
    font-size: 9px
    cursor: default
    padding-right: 5px
  &__info
    line-height: 12px
    font-size: 9px
    padding-right: 3px
    text-align: right
    color: #bababa
    cursor: default
</style>
