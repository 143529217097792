<template lang="pug">
  .field(v-if="!hidden")
    template(v-if="field.type === 'title'")
      MDinput(
        v-model="currentValue"
        :maxlength="100"
        :name="field.name"
        :required="field.required"
        :placeholder="field.placeholder"
        :prop="field.title"
        class="options-page__m-d-input"
      ) {{ field.title }}

    template(v-else-if="field.type === 'hidden'")
      // nothing

    template(v-else-if="field.type === 'text'")
      text-field(
        v-model="currentValue"
        :field="field"
      )

    template(v-else-if="field.type === 'slug'")
      slug-field(
        v-model="currentValue"
        :field="field"
      )

    template(v-else-if="field.type === 'date'")
      el-date-picker(
        v-model="currentValue"
        type="date"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd HH:mm:ss"
        :placeholder="field.placeholder || 'Выберите дату'"
      )

    template(v-else-if="field.type === 'date-range'")
      el-date-picker(
        v-model="currentValue"
        type="daterange"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd HH:mm:ss"
        range-separator="До"
        :start-placeholder="field.start_placeholder || 'Дата начала'"
        :end-placeholder="field.end_placeholder || 'Дата конца'"
      )

    template(v-else-if="field.type === 'datetime'")
      el-date-picker(
        v-model="currentValue"
        type="datetime"
        format="yyyy-MM-dd HH:mm:ss"
        value-format="yyyy-MM-dd HH:mm:ss"
        :placeholder="field.placeholder || 'Выберите дату и время'"
      )

    template(v-else-if="field.type === 'datetime-range'")
      el-date-picker(
        v-model="currentValue"
        type="datetimerange"
        format="yyyy-MM-dd HH:mm:ss"
        value-format="yyyy-MM-dd HH:mm:ss"
        range-separator="До"
        :start-placeholder="field.start_placeholder || 'Дата и время начала'"
        :end-placeholder="field.end_placeholder || 'Дата и время конца'"
      )

    template(v-else-if="field.type === 'rate'")
      el-rate(
        v-model="currentValue"
        :max="field.max || 3"
        :colors="['#99A9BF', '#F7BA2A', '#FF9900']"
        :low-threshold="field.low || 1"
        :high-threshold="field.high || 3"
        style="margin-top:8px;"
      )

    template(v-else-if="field.type === 'editor'")
      EditorField(
        :field="field"
        v-model="currentValue"
      )

    template(v-else-if="field.type === 'file'")
      SingleFile(
        :field="field"
        v-model="currentValue"
      )

    template(v-else-if="field.type === 'multi-file'")
      MultipleFile(
        :field="field"
        v-model="currentValue"
      )

    template(v-else-if="field.type === 'img'")
      SingleImage(
        :field="field"
        v-model="currentValue"
      )

    template(v-else-if="field.type === 'multi-img'")
      MultipleImage(
        :field="field"
        v-model="currentValue"
      )

    template(v-else-if="field.type === 'repeater'")
      repeater-field(
        v-model="currentValue"
        :field="field"
        @reset="reset"
      )

    template(v-else-if="field.type === 'number'")
      el-input-number(
        v-model="currentValue"
        :step="field.step || 1"
        :min="field.min || 0"
        :max="field.max || 100000"
      )

    template(v-else-if="field.type === 'select'")
      el-select(v-model="currentValue" :placeholder="field.placeholder || 'Выберите'" :multiple="field.multiple" collapse-tags)
        el-option(
          v-for="(label, key) in field.options"
          :key="label[0]"
          :label="label[1]"
          :value="label[0]"
        )

    template(v-else-if="field.type === 'block'")
      h2.field__block(
        v-html="field.title"
      )

    template(v-else-if="field.type === 'instashop-repeater'")
      instashop-repeater-field(
        v-model="currentValue"
        :field="field"
      )

    template(v-else-if="field.type === 'switch'")
      el-switch(
        v-model="currentValue"
        :field="field"
      )

    template(v-else-if="field.type === 'checkbox'")
      el-checkbox-group(
        v-model="currentValue"
        :field="field"
      )
        el-checkbox(
          v-for="(item, index) in field.options"
          :key="index"
          :label="item[0]"
        ) {{item[1]}}

    template(v-else-if="field.type === 'flexible'")
      flexible-field(
        v-model="currentValue"
        :field="field"
      )

    template(v-else-if="field.type === 'relation-belongs'")
      relation-belongs-field(
        v-model="currentValue"
        :field="field"
      )

    template(v-else-if="field.type === 'relation-has-many'")
      relation-has-many-field(
        v-model="currentValue"
        :field="field"
      )

    template(v-else-if="field.type === 'table'")
      table-field(
        v-model="currentValue"
        :field="field"
      )

    template(v-else-if="field.type === 'group'")
      group-field(
        v-model="currentValue"
        :field="field"
        ref="field"
      )

    template(v-else-if="field.type === 'dataset'")
      dataset-field(
        v-model="currentValue"
        :field="field"
        ref="field"
      )

    template(v-else-if="field.type === 'document'")
      document-field(
        v-model="currentValue"
        :field="field"
        ref="field"
      )

    template(v-else-if="field.type === 'documents_section'")
      documents-section-field(
        v-model="currentValue"
        :field="field"
        ref="field"
      )

    template(v-else-if="field.type === 'message' || field.type === 'message-file'")
      div(v-html="currentValue")
</template>
<script>
  import MDinput from '@/components/MDinput'
  import SingleFile from '@/components/Upload/SingleFile'
  import MultipleFile from '@/components/Upload/MultipleFile'
  import SingleImage from '@/components/Upload/SingleImage'
  import MultipleImage from '@/components/Upload/MultipleImage'
  import TextField from './TextField'
  import SlugField from './SlugField'
  import EditorField from './EditorField'
  import RelationBelongsField from './RelationBelongsField'
  import TableField from './TableField'
  import RelationHasManyField from './RelationHasManyField'
  import GroupField from './GroupField'
  import DatasetField from './DatasetField'
  import DocumentField from './DocumentField'
  import DocumentsSectionField from './DocumentsSectionField'
  // import RepeaterField from './RepeaterField';
  // import FlexibleField from './FlexibleField';

  const wait = async(ms) => {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  export default {
    name: 'Field',
    props: {
      field: { type: Object, default: () => ({}) },
      value: { type: [Object, Number, Array, String, Boolean], default: null }
    },
    components: {
      RelationHasManyField,
      EditorField,
      MDinput,
      SingleFile,
      MultipleFile,
      SingleImage,
      MultipleImage,
      TextField,
      RelationBelongsField,
      TableField,
      GroupField,
      DatasetField,
      DocumentField,
      DocumentsSectionField,
      SlugField
      // RepeaterField,
      // FlexibleField
    },
    data() {
      return {
        currentValue: this.value,
        hidden: false
      }
    },
    created() {
      if (this.currentValue === null) {
        this.currentValue = typeof this.field.default === 'object' ? JSON.parse(JSON.stringify(this.field.default)) : this.field.default
      }
    },
    methods: {
      async reset() {
        this.currentValue = this.field.default
        this.hidden = true
        await this.$nextTick()
        this.hidden = false
      }
    },
    computed: {
    },
    watch: {
      currentValue: {
        deep: true,
        handler() {
          this.$emit('input', this.currentValue)
        }
      },
      value: {
        deep: true,
        handler() {
          this.currentValue = this.value
        }
      }
    }
  }
</script>
<style lang="sass">
  .field
    // background-color: blue
    .el-date-editor
      &--daterange.el-input__inner
        width: 450px
      .el-range-separator
        width: 8%

    &__block
      font-size: 20px
      color: #606266
      font-weight: 700
      padding-left: 20px

    // margin-bottom: 25px
</style>
