const state = {
  fields: [],
  data: {},
  sections: [],
  page: '',
  viewUrl: ''
}

const mutations = {
  SET_PAGE: (state, page) => {
    state.page = page
  },
  SET_DATA: (state, data) => {
    state.data = data
  },
  SET_FIELDS: (state, fields) => {
    state.fields = fields
  },
  SET_VIEW_URL: (state, payload) => {
    state.viewUrl = payload
  },
  ADD_DOCUMENTS_SECTION: (state, object) => {
    if (!state.sections.includes(object)) {
      state.sections.push(object)
    }
  },
  REMOVE_DOCUMENTS_SECTION: (state, object) => {
    if (state.sections.includes(object)) {
      state.sections.splice(state.sections.indexOf(object), 1)
    }
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
