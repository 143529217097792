import { catchRequest, default as request } from '@/utils/api'

export function twoFactorLogin(email, password, googleRecaptchaToken) {
  return catchRequest({
    url: '/auth/two-factor/login',
    method: 'post',
    data: {
      email,
      password,
      googleRecaptchaToken
    },
  })
}
export function twoFactorSecret(intermediateToken, secret) {
  return catchRequest({
    url: '/auth/two-factor/secret',
    method: 'post',
    data: {
      intermediateToken,
      secret
    },
  })
}
export function updatePassword(tempToken, newPassword) {
  return catchRequest({
    url: '/auth/update-password',
    method: 'post',
    data: {
      tempToken,
      newPassword
    },
  })
}


export function login(email, password, googleRecaptchaToken) {
  return catchRequest({
    url: '/auth/login',
    method: 'post',
    data: {
      email,
      password,
      googleRecaptchaToken
    },
  })
}

export function getInfo() {
  return request({
    url: '/auth/user',
    method: 'get',
  })
}

export function logout() {
  return request({
    url: '/auth/logout',
    method: 'post',
  })
}
