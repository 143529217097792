<template lang="pug">
  .navbar
    .navbar__right
      // hamburger#hamburger-container(:is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar")
      breadcrumb#breadcrumb-container.breadcrumb-container(style="margin-left: 15px")

    //- .navbar__domain {{ ($store.state.app.domains.filter(el => el.name === $store.state.app.domain)[0] || {}).title }}


    .right-menu
      .navbar__person Вы вошли как: #[span.navbar__person-name {{ $store.state.user.name }}] | {{ $store.getters.roleName }}
      template(v-if="device!=='mobile'")
        // search#header-search.right-menu-item

        // screenfull#screenfull.right-menu-item(hover-effect)

        // el-tooltip(:content="$t('navbar.size')" effect="dark" placement="bottom")
          size-select#size-select.right-menu-item.hover-effect

        // lang-select.right-menu-item.hover-effect

      el-dropdown.avatar-container.right-menu-item.hover-effect(trigger="click")
        .avatar-wrapper
          // i.el-icon-user-solid(style="width: 40px;height: 40px")
          //- img.user-avatar(:src="avatar+'/128'")
          .user-avatar
            svg-icon(icon-class="user")
          i.el-icon-caret-bottom

        el-dropdown-menu(slot="dropdown")
          // router-link(to="/admin")
            el-dropdown-item {{ $t('navbar.dashboard') }}

          router-link(v-show="userId !== null" :to="`/admin/password-change`")
            el-dropdown-item Изменить пароль

          // router-link(v-show="userId !== null" :to="`/administrator/users/edit/${userId}`")
            el-dropdown-item {{ $t('navbar.profile') }}

          //- <a target="_blank" href="https://github.com/tuandm/laravue/">
            <el-dropdown-item>
              {{ $t('navbar.github') }}
            </el-dropdown-item>
            </a>

          el-dropdown-item(divided)
            span(style="display:block" @click="logout") {{ $t('navbar.logOut') }}
</template>

<script>
  import { mapGetters } from 'vuex'
  import Breadcrumb from '@/components/Breadcrumb'
  import Hamburger from '@/components/Hamburger'
  import Screenfull from '@/components/Screenfull'
  import SizeSelect from '@/components/SizeSelect'
  import LangSelect from '@/components/LangSelect'
  // import Search from '@/components/HeaderSearch';

  export default {
    components: {
      Breadcrumb,
      Hamburger,
      Screenfull,
      SizeSelect,
      LangSelect,
      // Search
    },
    computed: {
      ...mapGetters([
        'sidebar',
        'name',
        'avatar',
        'device',
        'userId'
      ])
    },
    methods: {
      toggleSideBar() {
        this.$store.dispatch('app/toggleSideBar')
      },
      async logout() {
        await this.$store.dispatch('user/logout')
        this.$router.push(`/login?redirect=${this.$route.fullPath}`)
      }
    }
  }
</script>
<style lang="sass" scoped>
  .navbar
    height: 50px
    overflow: hidden
    position: relative
    padding: 0
    background: #fff
    box-shadow: 0 1px 4px rgba(0, 21, 41, .08)

    display: flex
    justify-content: space-between

    &__right
      display: flex
      flex-flow: row nowrap

    .navbar__domain
      // float: left
      line-height: 50px
      padding: 0 20px 0 50px
      color: rgb(3, 69, 101)
      font-weight: 400
      font-size: 20px

    &__person
      font-size: 14px
      line-height: 50px
      padding: 0 20px 0 50px
      color: rgb(3, 98, 135)
      font-weight: 400

    &__person-name
      font-weight: 600


    .hamburger-container
      line-height: 46px
      height: 100%
      // float: left;
      cursor: pointer
      transition: background .3s
      -webkit-tap-highlight-color: transparent

      &:hover
        background: rgba(0, 0, 0, .025)


    .errLog-container
      display: inline-block
      vertical-align: top

    .right-menu
      display: flex
      flex-flow: row nowrap
      // float: right
      height: 100%
      line-height: 50px

      &:focus
        outline: none

      .right-menu-item
        display: inline-block
        padding: 0 8px
        height: 100%
        font-size: 18px
        color: #5a5e66
        vertical-align: text-bottom

        &.hover-effect
          cursor: pointer
          transition: background .3s

          &:hover
            background: rgba(0, 0, 0, .025)

      .avatar-container
        margin-right: 30px

        .avatar-wrapper
          margin-top: 5px
          position: relative

          .user-avatar
            cursor: pointer
            width: 40px
            height: 40px
            border-radius: 4px
            padding: 5px
            .svg-icon
              width: 100%
              height: 100%

          .el-icon-caret-bottom
            cursor: pointer
            position: absolute
            right: -20px
            top: 25px
            font-size: 12px

</style>
