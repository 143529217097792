<template lang="pug">
  .upload-file-browser
    el-button.upload-file-browser__btn(type="text" @click="dialogVisible = true") Ссылки
    el-dialog.upload-file-browser__dialog(title="Ссылки" :visible.sync="dialogVisible" top="5vh")
      link-tree.upload-file-browser__dialog-body(v-if="dialogVisible" :base="base" :files="files.map(el => el.id)" :limit="limit" @choose="choose")
      .upload-file-browser__dialog-buttons(slot="footer")
        el-button.upload-file-browser__dialog-btn(@click="cancel") Отменить
        el-button.upload-file-browser__dialog-btn(type="primary" @click="confirm") Подтвердить
</template>

<script>
  import LinkTree from './LinkTree'
  export default {
    name: 'LinkBrowser',
    components: { LinkTree },
    props: {
      base: {
        type: String,
        default: '/'
      },
      files: {
        type: Array,
        default: () => []
      },
      limit: {
        type: Number,
        default: 1000
      }
    },
    data() {
      return {
        dialogVisible: false,
        chosen: this.files
      }
    },
    computed: {
    },
    watch: {
    },
    methods: {
      choose(files) {
        this.chosen = files
        this.$emit('choose', this.chosen)
      },
      confirm() {
        this.dialogVisible = false
        this.$emit('input', this.chosen)
      },
      cancel() {
        this.dialogVisible = false
      }
    },
  }
</script>
<style rel="stylesheet/sass" lang="sass">
  @import "@/assets/sass/mixin.scss"
  .upload-file-browser
    .el-dialog
      margin-top: 5vh
      width: 60vw
      max-height: 90vh
      display: flex
      flex-flow: column nowrap
      &__body
        max-height: 100%
        overflow-y: auto
    &__dialog
      &-buttons
        display: flex
        width: 100%
      &-btn
        width: 50%

</style>
