<template lang="pug">
  .repeater-field(:class="{ 'repeater-field_readonly': field.readonly }")
    .repeater-field__top-actions
      // (v-if="field.accordion")
      el-button(@click="openListHideAll()" v-if="field.accordion") Свернуть все
      el-button(@click="openListShowAll()") Развернуть все
      el-button(@click="openCodeModal()") Код поля

    template(v-if="field.view === 'table'")
      table-list(
        v-model="currentValue"
        :field="field"
        ref="list"
      )
    template(v-else-if="field.view === 'block'")
      block-list(
        v-model="currentValue"
        :field="field"
        ref="list"
      )
    template(v-else)
      h3 Данный вид повторителя не определен

    el-dialog.code-dialog(:visible.sync="codeDialogVisible" :title="'Код поля'")
      .form-container
        el-form.edit-container
          el-input.code-dialog-input(autosize v-model="codeDialogInput" rows="10" type="textarea")
        div(style="text-align:right;margin-top: 20px")
          el-button(type="danger" @click="codeModalCancel()") Отменить
          el-button(type="primary" @click="codeModalSave()") Сохранить

    field-meta(ref="meta" element-name="элементов" :len="currentValue.length" :min="field.min" :max="field.max" :errors="[]")
</template>
<script>
  import TableList from './TableList'
  import BlockList from './BlockList'
  import FieldMeta from '@/components/fields/FieldMeta'

  export default {
    name: 'RepeaterField',
    components: {
      FieldMeta,
      TableList,
      BlockList
    },
    props: {
      field: { type: Object, default: () => ({}) },
      value: { type: [Object, Number, Array, String], default: null }
    },
    data() {
      return {
        currentValue: this.value || this.field.default || [],
        codeDialogVisible: false,
        codeDialogInput: ''
      }
    },
    created() {
      if (!Array.isArray(this.currentValue)) {
        this.currentValue = []
      }
      if (this.field.documentsSection) {
        this.$store.commit('page/ADD_DOCUMENTS_SECTION', this)
      }
    },
    beforeDestroy() {
      if (this.field.documentsSection) {
        this.$store.commit('page/REMOVE_DOCUMENTS_SECTION', this)
      }
    },
    async mounted() {
      while (this.currentValue.length < this.field.min) {
        this.list.handleInsert(this.currentValue.length - 1)
      }
      await this.$nextTick()
      this.checkErrors()
    },
    watch: {
      currentValue: {
        deep: true,
        handler() {
          this.checkErrors()
          this.$emit('input', this.currentValue)
        }
      },
      value: {
        deep: true,
        handler() {
          // this.checkEditors();
          this.currentValue = this.value
        }
      }
    },
    computed: {
      list() {
        return this.$refs.list
      }
    },
    methods: {
      unshift(el) {
        this.$refs.list.handleInsert(-1)
        Object.keys(this.currentValue[0]).forEach(key => {
          this.currentValue[0][key] = el[key]
        })
      },
      splice(start, deleteCount) {
        this.currentValue.splice(start, deleteCount)
      },
      getDocumentsSectionTitle() {
        return this.field.documentsSection
      },
      openCodeModal() {
        this.codeDialogVisible = true
        this.codeDialogInput = JSON.stringify(this.currentValue, null, '  ')
      },
      codeModalSave() {
        // console.log(this.codeDialogInput.replace("\\n", '').substr(4740, 100));
        try {
          this.currentValue = JSON.parse(this.codeDialogInput)
        } catch (err) {
          alert(err.message)
        }
        // eval('this.currentValue = ' + this.codeDialogInput);
        this.codeDialogVisible = false
      },
      codeModalCancel() {
        this.codeDialogInput = ''
        this.codeDialogVisible = false
      },
      openListHideAll() {
        this.list.openListHideAll()
      },
      openListShowAll() {
        this.list.openListShowAll()
      },
      checkErrors() {
        this.$refs.meta.check()
      }
    },
  }
</script>
<style lang="sass">
  @import "index.sass"
</style>
