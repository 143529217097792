import Cookies from 'js-cookie'
import { getLanguage } from '@/lang/index'

const domains = (window.__data.domains || []).map(el => ({ title: el.title, name: el.name, indexPath: el.indexPath }))
const env = (window.__data.env || {})

const state = {
  sidebar: {
    opened: true,
    // opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
    withoutAnimation: false,
  },
  device: 'desktop',
  language: getLanguage(),
  size: Cookies.get('size') || 'medium',
  domain: (domains[0] || {}).name,
  domains: domains,
  env: env
}

const mutations = {
  TOGGLE_SIDEBAR: state => {
    // state.sidebar.opened = !state.sidebar.opened;
    // state.sidebar.withoutAnimation = false;
    // if (state.sidebar.opened) {
    //   Cookies.set('sidebarStatus', 1);
    // } else {
    //   Cookies.set('sidebarStatus', 0);
    // }
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    // Cookies.set('sidebarStatus', 0);
    // state.sidebar.opened = false;
    // state.sidebar.withoutAnimation = withoutAnimation;
  },
  TOGGLE_DEVICE: (state, device) => {
    // state.device = device;
  },
  SET_LANGUAGE: (state, language) => {
    state.language = language
    Cookies.set('language', language)
  },
  SET_SIZE: (state, size) => {
    state.size = size
    Cookies.set('size', size)
  },
  SET_DOMAIN: (state, domain) => state.domain = domain
}

const actions = {
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit('CLOSE_SIDEBAR', withoutAnimation)
  },
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device)
  },
  setLanguage({ commit }, language) {
    commit('SET_LANGUAGE', language)
  },
  setSize({ commit }, size) {
    commit('SET_SIZE', size)
  },
  setDomain({ commit }, domain) {
    commit('SET_DOMAIN', domain)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
