<template lang="pug">
  .condition-field(v-if="!isHiddenByCondition")
    field(
      v-if="field.no_form_item === true || field.no_title || tableCell"
      :field="field"
      v-model="currentValue"
      ref="field"
    )
    .condition-field__wide(v-else-if="field.wide === true")
      h4.condition-field__wide-title.condition-field__wide-title_required {{ field.title }}
      field(:field="field" v-model="currentValue" ref="field")
    el-form-item(
      :label-width="`${($route.meta.labelWidth || 150)}px`"
      :label="field.title"
      :required="field.required"
      v-else
    )
      field(:field="field" v-model="currentValue" ref="field")
</template>
<script>
  // import Field from './Field';
  import isHiddenByCondition from '@/components/fields/utils/is-hidden-by-conditions'

  export default {
    name: 'ConditionField',
    props: {
      field: { type: Object, default: () => ({}) },
      value: { type: [Object, Number, Array, String, Boolean], default: null },
      allValues: { type: Object, default: () => ({}) },
      tableCell: { type: Boolean, default: false }
    },
    components: {
      // Field
    },
    data() {
      return {
        currentValue: this.value
      }
    },
    created() {
    },
    methods: {
      getIsHiddenByCondition() {
        return this.isHiddenByCondition
      }
    },
    computed: {
      isHiddenByCondition() {
        return isHiddenByCondition(this.field.conditions, this.allValues)
      }
    },
    watch: {
      currentValue: {
        deep: true,
        handler() {
          this.$emit('input', this.currentValue)
        }
      },
      value: {
        deep: true,
        handler() {
          this.currentValue = this.value
        }
      }
    }
  }
</script>
<style lang="sass">
  .condition-field
    &__wide
      &-title
        font-size: 16px
        color: #606266
        &_required
          &:before
            content: '*'
            color: #ff4949
            margin-right: 4px

  .repeater-field, .dataset
    h4
      margin-top: 0.8em
      margin-bottom: 0.3em
    .condition-field__wide:first-child
      h4
        margin-top: 0
</style>
