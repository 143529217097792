import Vue from 'vue'
// import Cookies from 'js-cookie';
// import ElementUI from 'element-ui';

import '@/assets/sass/index.scss'

import router from '@/router'
import store from '@/store'

import '@/icons' // icon
import '@/permission' // permission control

import App from '@/views/App'
import i18n from '@/lang' // Internationalization

import Field from '@/components/fields/Field.vue'
import RepeaterField from '@/components/fields/RepeaterField'
import FlexibleField from '@/components/fields/FlexibleField'
import FileManager from 'laravel-file-manager'

import * as filters from './filters' // global filters

import '@/element-import'

// Vue.use(ElementUI, {
//   size: Cookies.get('size') || 'medium', // set element-ui default size
//   i18n: (key, value) => i18n.t(key, value),
// });

Vue.component('Field', Field)
Vue.component('RepeaterField', RepeaterField)
Vue.component('FlexibleField', FlexibleField)

Vue.use(FileManager, { store })

// register global utility filters.
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.prototype.$imaginary = function(path, method = 'crop', paras = {}) {
  if (!path || typeof path !== 'string') {
    return ''
  }
  if (['.pdf', '.svg'].includes(path.substr(-4))) {
    return path.replace('http://nginx', 'http://localhost')
  }
  const env = this.$store.state.app.env
  const IMAGINARY_HOST = env ? env.IMAGINARY_HOST : ''
  const IMG_HOST = env ? env.FFU_HOST : ''

  const arr = []
  paras.url = path.substr(0, 4) === 'http' ? path : `${IMG_HOST}/${path.replace(/^\//g, '')}`
  for (const key in paras) {
    arr.push(`${key}=${paras[key]}`)
  }

  return `${IMAGINARY_HOST}/${method}?${arr.join('&')}`
}

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: h => h(App),
})

