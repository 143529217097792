import Draggable from 'vuedraggable'
import ConditionField from '@/components/fields/ConditionField'
import makeHash from '@/utils/make-hash'

export default {
  components: {
    ConditionField,
    Draggable
  },
  props: {
    field: { type: Object, default: () => ({}) },
    value: { type: Array, default: null }
  },
  data() {
    return {
      currentValue: this.value,
      openList: this.field.open_list,
      showReInitFields: true,
      fieldStyles: {},
      reInitFieldTypes: ['editor', 'img', 'multi-img', 'file', 'multi-file', 'document', 'documents-section'],
      drag: false
    }
  },
  watch: {
    currentValue: {
      deep: true,
      handler() {
        this.$emit('input', this.currentValue)
      }
    },
    value: {
      deep: true,
      handler() {
        // this.checkEditors();
        this.currentValue = this.value
      }
    }
  },
  computed: {
    dragEvents() {
      return {
        start: () => {
          this.drag = true
        },
        end: () => {
          this.drag = false
          this.checkEditors()
        }
      }
    },
    dragOptions() {
      return {
        animation: 200,
        draggable: '.repeater-field__draggable-item',
        handle: '.repeater-field__handle',
        disabled: false,
        ghostClass: 'repeater-field__ghost'
      }
    },
    lang() {
      return this.$store.getters.language
    },
    defaultRow() {
      const res = {}
      this.field.fields.forEach(el => {
        res[el.name] = el.default || null
      })
      return res
    }
  },
  methods: {
    copy(obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    openListHideAll() {
      this.openList = []
    },
    openListShowAll() {
      this.openList = this.currentValue.map((el, index) => index)
    },
    rowHash(row) {
      return Object.values(row).join('_')
    },
    getDefaultRow() {
      const res = {
        _hash: makeHash(20)
      }
      this.field.fields.forEach(el => {
        res[el.name] = el.default
      })
      return res
    },
    checkEditors() {
      const reInitFields = this.field.fields.filter(field => this.reInitFieldTypes.includes(field.type))
      if (!reInitFields.length) {
        return
      }
      reInitFields.forEach(field => {
        this.currentValue.forEach(row => {
          const id = `field__${row._hash}__${field.name}`
          const $el = (this.$refs[id] || [])[0] || this.$refs[id]
          if ($el) {
            this.fieldStyles[id] = 'height: ' + $el.clientHeight + 'px'
          }
        })
      })
      this.showReInitFields = false
      setTimeout(() => {
        this.showReInitFields = true
        this.fieldStyles = {}
      }, 0)
    },
    handleInsert(index) {
      const insertIndex = index + 1
      this.openList = this.openList.map(el => {
        if (el >= insertIndex) {
          return el + 1
        }
        return el
      })
      this.openList = [...this.openList, insertIndex]
      this.checkEditors()
      this.currentValue.splice(insertIndex, 0, this.copy(this.getDefaultRow()))
    },
    handleDelete(index) {
      const item = this.currentValue[index]
      if (item.document && item.document.file && item.document.file.model === 'link') {
        this.$confirm(`Удалить элемент №${index + 1}? Данный документ находится и в других разделах. Он будет удален только из данного раздела.`, 'Подтвердите удаление', {
          confirmButtonText: 'Удалить',
          cancelButtonText: 'Отменить',
          // type: 'warning'
        }).then(() => {
          this.checkEditors()
          this.currentValue.splice(index, 1)
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: 'Удаление отменено'
          // });
        })
        return
      }
      this.$confirm(`Удалить элемент №${index + 1}?`, 'Подтвердите удаление', {
        confirmButtonText: 'Удалить',
        cancelButtonText: 'Отменить',
        // type: 'warning'
      }).then(() => {
        this.checkEditors()
        this.currentValue.splice(index, 1)
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: 'Удаление отменено'
        // });
      })
    },
    handleDuplicate(index) {
      this.checkEditors()
      const newItem = JSON.parse(JSON.stringify(this.currentValue[index]))
      this.field.fields.forEach(el => {
        const component = this.$refs[el.name][index].$refs.field.$refs.field
        if (component && component.getDuplicateData) {
          newItem[el.name] = JSON.parse(JSON.stringify(component.getDuplicateData()))
        }
      })
      newItem._hash = makeHash(20)
      this.currentValue.splice(index + 1, 0, newItem)
    },
    handleUp(index) {
      if (index < 1 || index > this.currentValue.length - 1) {
        return
      }
      // let before = {...this.currentValue};
      this.checkEditors()
      this.currentValue.splice(index - 1, 2, this.currentValue[index], this.currentValue[index - 1])
      // console.log(index, this.currentValue, before);
    },
    handleDown(index) {
      if (index < 0 || index > this.currentValue.length - 2) {
        return
      }
      // let before = {...this.currentValue};
      this.checkEditors()
      this.currentValue.splice(index, 2, this.currentValue[index + 1], this.currentValue[index])
      // console.log(index, this.currentValue, before);
    },
    renderAccordionTitle(data) {
      let template = this.field.accordion_title
      Object.keys(data).forEach(key => {
        if (typeof data[key] !== 'object') {
          template = template.replace(`{${key}}`, data[key])
        }
      })
      return template
    }
  },
}
