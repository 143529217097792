<template lang="pug">
  .document-field(
    v-if="currentValue"
    :class="{\
      'document-field_has-move-to': places.length,\
      'document-field_file-is-link': hasFile\
    }"
  )
    text-field(v-model="currentValue.title" :field="{ min: field.min, max: field.max, rows: field.rows, replace_br: field.replace_br }")
    el-tooltip(effect="dark" v-if="hasFile" placement="top")
      div(slot="content")
        .document-field__link-title
        | Ссылка на документ{{ ' ' }}
        a.document-field__link-edit(:href="linkRoute" target="_blank") изменить
        br
        | Имя: {{ currentValue.file ? currentValue.file.title : '' }}
        br
        | Ссылка:&nbsp;
        a(:href="currentValue.file ? currentValue.file.url : ''" target="_blank") {{ currentValue.file ? currentValue.file.url : '' }}
      svg-icon.document-field__link-icon(icon-class="link-icon")
    file-field(
      v-model="currentValue.file"
      @load-link="currentValue.title = $event.title"
      :field="{ required: field.required, allow_links: true, limit: 1, browser: true, direction: 'horizontal' }"
    )
    el-dropdown.document-field__move(trigger="click" @command="moveToSection" v-if="places.length")
      span.el-dropdown-link
        | Перенести в
        i.el-icon-arrow-down.el-icon--right
      el-dropdown-menu(slot="dropdown")
        el-dropdown-item(v-for="place in places" :key="place.path" :command="place.index") {{ place.title }}
        //  icon="el-icon-plus"
</template>
<script>
  import TextField from '../TextField'
  import FileField from '@/components/Upload/SingleFile'
  export default {
    name: 'DocumentField',
    props: {
      field: { type: Object, default: () => ({}) },
      value: { type: Object, default: null }
    },
    components: {
      TextField,
      FileField
    },
    data() {
      return {
        errors: [],
        currentValue: this.value
      }
    },
    watch: {
      currentValue: {
        deep: true,
        handler() {
          this.$emit('input', this.currentValue)
        }
      },
      value: {
        deep: true,
        handler() {
          this.currentValue = this.value
        }
      }
    },
    computed: {
      sections() {
        return this.$store.state.page.sections
      },
      parentRepeater() {
        let el = this
        while (el.$options.name !== 'RepeaterField') {
          el = el.$parent
        }
        return el
      },
      currentPlace() {
        if (this.parentRepeater.$parent.$options.name === 'DocumentsSectionField') {
          return this.parentRepeater.$parent
        }
        return this.parentRepeater
      },
      currentDocumentIndex() {
        return this.parentRepeater.$refs.list.$refs.document.map(el => el.$refs.field.$refs.field).indexOf(this)
      },
      places() {
        // console.log(this.sections);
        return this.sections
          .map((el, index) => ({ index: index, section: el }))
          .filter(el => el.section !== this.currentPlace)
          .map(el => ({
            title: el.section.getDocumentsSectionTitle(),
            index: el.index
          }))
      },
      lang() {
        return this.$store.getters.language
      },
      hasFile() {
        return this.currentValue.file && this.currentValue.file.model === 'link'
      },
      linkRoute() {
        const domain = this.$store.getters.currentDomain.name
        console.log(`/${domain}/links/edit/${this.currentValue.file.id}`)
        return `/${domain}/links/edit/${this.currentValue.file.id}`
      },
    },
    methods: {
      moveToSection(index) {
        this.sections[index].unshift(JSON.parse(JSON.stringify({ document: this.currentValue })))
        this.currentPlace.splice(this.currentDocumentIndex, 1)
      }
    }
  }
</script>
<style rel="stylesheet/sass" lang="sass">
  @import './index'
</style>
