<template lang="pug">
  .table-field
    .table-field__table-block
      table.table-field__table(border="0" cellpadding="0" cellspacing="0")
        thead.table-field__head
          tr.table-field__empty-row
            th.table-field__empty-cell
            th.table-field__empty-cell(v-for="(cell, cellIndex) in currentValue.head[0]" :key="cellIndex")
              el-tooltip(effect="light" content="Добавить колонку" placement="top-start")
                el-button.table-field-action__column.table-field-action__column_top.table-field-action__column_add(
                  icon="el-icon-plus"
                  size="mini"
                  circle
                  @click.prevent="handleAddColumn(cellIndex)"
                )
              el-tooltip(effect="light" content="Удалить колонку" placement="top")
                el-button.table-field-action__column.table-field-action__column_top.table-field-action__column_delete(
                  icon="el-icon-minus"
                  size="mini"
                  circle
                  @click.prevent="handleDeleteColumn(cellIndex)"
                )
            th.table-field__empty-cell
              el-tooltip(effect="light" content="Добавить колонку" placement="top-end")
                el-button.table-field-action__column.table-field-action__column_top.table-field-action__column_add.table-field-action__column_last.table-field-action__column_intersected(
                  icon="el-icon-plus"
                  size="mini"
                  circle
                  @click.prevent="handleAddColumn(cols)"
                )
          tr.table-field__head-row(v-for="(row, rowIndex) in currentValue.head" :key="rowIndex")
            th.table-field__empty-cell
            th.table-field__head-cell(v-for="(cell, cellIndex) in row" v-if="isVisible(currentValue.head, rowIndex, cellIndex)" :key="cellIndex" :colspan="cell.horizontalUnion" :rowspan="cell.verticalUnion")
              .table-field__head-inner
                el-input.table-field__head-input(v-model="cell.content" type="textarea" autosize)
                el-tooltip(effect="light" content="Отменить объединение вправо" placement="right" v-if="cell.horizontalUnion > 1")
                  el-button.table-field-action__union-right.table-field-action__union-right_decrease(
                    icon="el-icon-connection"
                    size="mini"
                    circle
                    @click.prevent="handleUnionRightDecrease(cell)"
                  )
                el-tooltip(effect="light" content="Объединить вправо" placement="right" v-if="canUnionRight(currentValue.head, rowIndex, cellIndex)")
                  el-button.table-field-action__union-right.table-field-action__union-right_increase(
                    icon="el-icon-connection"
                    size="mini"
                    circle
                    @click.prevent="handleUnionRightIncrease(cell)"
                  )
                el-tooltip(effect="light" content="Отменить объединение вниз" placement="bottom" v-if="cell.verticalUnion > 1")
                  el-button.table-field-action__union-bottom.table-field-action__union-bottom_decrease(
                    icon="el-icon-connection"
                    size="mini"
                    circle
                    @click.prevent="handleUnionBottomDecrease(cell)"
                  )
                el-tooltip(effect="light" content="Объединение вниз" placement="bottom" v-if="canUnionBottom(currentValue.head, rowIndex, cellIndex)")
                  el-button.table-field-action__union-bottom.table-field-action__union-bottom_increase(
                    icon="el-icon-connection"
                    size="mini"
                    circle
                    @click.prevent="handleUnionBottomIncrease(cell)"
                  )
            th.table-field__empty-cell
              el-tooltip(effect="light" content="Добавить строку" placement="right-start")
                el-button.table-field-action__row.table-field-action__row_right.table-field-action__row_add(
                  icon="el-icon-plus"
                  size="mini"
                  circle
                  @click.prevent="handleAddHeadRow(rowIndex)"
                  :class="{'table-field-action__row_intersected': rowIndex === 0}"
                )
              el-tooltip(effect="light" content="Удалить строку" placement="right")
                el-button.table-field-action__row.table-field-action__row_right.table-field-action__row_delete(
                  icon="el-icon-minus"
                  size="mini"
                  circle
                  @click.prevent="handleDeleteHeadRow(rowIndex)"
                )
              el-tooltip(effect="light" content="Добавить строку" placement="right-end" v-if="rowIndex === currentValue.head.length - 1")
                el-button.table-field-action__row.table-field-action__row_right.table-field-action__row_add.table-field-action__row_last(
                  icon="el-icon-plus"
                  size="mini"
                  circle
                  @click.prevent="handleAddHeadRow(rowIndex + 1)"
                )
        tbody.table-field__body
          tr.table-field__row(v-for="(row, rowIndex) in currentValue.rows" :key="rowIndex")
            td.table-field__empty-cell
              el-tooltip(effect="light" content="Добавить строку" placement="left-start")
                el-button.table-field-action__row.table-field-action__row_left.table-field-action__row_add(
                  icon="el-icon-plus"
                  size="mini"
                  circle
                  @click.prevent="handleAddRow(rowIndex)"
                )
              el-tooltip(effect="light" content="Удалить строку" placement="left")
                el-button.table-field-action__row.table-field-action__row_left.table-field-action__row_delete(
                  icon="el-icon-minus"
                  size="mini"
                  circle
                  @click.prevent="handleDeleteRow(rowIndex)"
                )
              el-tooltip(effect="light" content="Добавить строку" placement="left-end" v-if="rowIndex === currentValue.rows.length - 1")
                el-button.table-field-action__row.table-field-action__row_left.table-field-action__row_add.table-field-action__row_last(
                  icon="el-icon-plus"
                  size="mini"
                  circle
                  @click.prevent="handleAddRow(rowIndex + 1)"
                )
            td.table-field__row-cell(v-for="(cell, cellIndex) in row"  v-if="isVisible(currentValue.rows, rowIndex, cellIndex)" :key="cellIndex" :colspan="cell.horizontalUnion" :rowspan="cell.verticalUnion")
              .table-field__row-inner
                el-input.table-field__row-input(v-model="cell.content" type="textarea" autosize)
                el-tooltip(effect="light" content="Отменить объединение вправо" placement="right" v-if="cell.horizontalUnion > 1")
                  el-button.table-field-action__union-right.table-field-action__union-right_decrease(
                    icon="el-icon-connection"
                    size="mini"
                    circle
                    @click.prevent="handleUnionRightDecrease(cell)"
                  )
                el-tooltip(effect="light" content="Объединить вправо" placement="right" v-if="canUnionRight(currentValue.rows, rowIndex, cellIndex)")
                  el-button.table-field-action__union-right.table-field-action__union-right_increase(
                    icon="el-icon-connection"
                    size="mini"
                    circle
                    @click.prevent="handleUnionRightIncrease(cell)"
                  )
                el-tooltip(effect="light" content="Отменить объединение вниз" placement="bottom" v-if="cell.verticalUnion > 1")
                  el-button.table-field-action__union-bottom.table-field-action__union-bottom_decrease(
                    icon="el-icon-connection"
                    size="mini"
                    circle
                    @click.prevent="handleUnionBottomDecrease(cell)"
                  )
                el-tooltip(effect="light" content="Объединение вниз" placement="bottom" v-if="canUnionBottom(currentValue.rows, rowIndex, cellIndex)")
                  el-button.table-field-action__union-bottom.table-field-action__union-bottom_increase(
                    icon="el-icon-connection"
                    size="mini"
                    circle
                    @click.prevent="handleUnionBottomIncrease(cell)"
                  )
            td.table-field__empty-cell

</template>
<script>

  const wait = async(ms) => {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  export default {
    name: 'TableField',
    props: {
      field: { type: Object, default: () => ({}) },
      value: { type: [Object, Number, Array, String, Boolean], default: null }
    },
    components: {
    },
    data() {
      return {
        currentValue: this.value
      }
    },
    created() {
      this.currentValue = this.checkValue()
    },
    methods: {
      makeCell() {
        return {
          content: '',
          horizontalUnion: 1,
          verticalUnion: 1
        }
      },
      checkValue() {
        let value = this.currentValue || {}
        value = JSON.parse(JSON.stringify(value))
        if (!value.head || !Array.isArray(value.head) || value.head.length === 0) {
          value.head = [[this.makeCell()]]
        }
        if (!value.rows || !Array.isArray(value.rows) || value.rows.length === 0) {
          value.rows = [value.head[0].map(el => this.makeCell())]
        }
        return {
          head: value.head,
          rows: value.rows
        }
      },
      makeRow() {
        const arr = []
        for (let i = 0; i < this.cols; i++) {
          arr.push(this.makeCell())
        }
        return arr
      },
      handleAddRow(index) {
        this.currentValue.rows.splice(index, 0, this.makeRow())
      },
      handleDeleteRow(index) {
        this.currentValue.rows.splice(index, 1)
      },
      handleAddHeadRow(index) {
        this.currentValue.head.splice(index, 0, this.makeRow())
      },
      handleDeleteHeadRow(index) {
        this.currentValue.head.splice(index, 1)
      },
      handleAddColumn(index) {
        [this.currentValue.head, this.currentValue.rows].forEach(rows => {
          rows.forEach(row => row.splice(index, 0, this.makeCell()))
        })
      },
      handleRemoveColumn(index) {
        [this.currentValue.head, this.currentValue.rows].forEach(rows => {
          rows.forEach(row => row.splice(index, 1))
        })
      },
      handleUnionRightIncrease(cell) {
        cell.horizontalUnion++
      },
      handleUnionRightDecrease(cell) {
        cell.horizontalUnion--
      },
      handleUnionBottomIncrease(cell) {
        cell.verticalUnion++
      },
      handleUnionBottomDecrease(cell) {
        cell.verticalUnion--
      },
      isVisible(rows, rowIndex, cellIndex) {
        for (let currentRowIndex = 0; currentRowIndex <= rowIndex; currentRowIndex++) {
          for (let currentCellIndex = 0; currentCellIndex <= cellIndex; currentCellIndex++) {
            const cell = rows[currentRowIndex][currentCellIndex]
            if (currentRowIndex + cell.verticalUnion - 1 >= rowIndex && currentCellIndex + cell.horizontalUnion - 1 >= cellIndex && (currentCellIndex < cellIndex || currentRowIndex < rowIndex)) {
              return false
            }
          }
        }
        return true
      },
      canUnionRight(rows, rowIndex, cellIndex) {
        if (cellIndex + rows[rowIndex][cellIndex].horizontalUnion >= this.cols) {
          return false
        }
        for (let i = 0; i < rows[rowIndex][cellIndex].verticalUnion; i++) {
          const v = rowIndex + i
          const h = cellIndex + rows[rowIndex][cellIndex].horizontalUnion
          if (!this.isVisible(rows, v, h) || rows[v][h].verticalUnion > 1 || rows[v][h].horizontalUnion > 1) {
            return false
          }
        }
        return true
      },
      canUnionBottom(rows, rowIndex, cellIndex) {
        if (rowIndex + rows[rowIndex][cellIndex].verticalUnion >= rows.length) {
          return false
        }
        for (let i = 0; i < rows[rowIndex][cellIndex].horizontalUnion; i++) {
          const v = rowIndex + rows[rowIndex][cellIndex].verticalUnion
          const h = cellIndex + i
          if (!this.isVisible(rows, v, h) || rows[v][h].verticalUnion > 1 || rows[v][h].horizontalUnion > 1) {
            return false
          }
        }
        return true
      },
    },
    computed: {
      cols() {
        return this.currentValue.head[0].length || 1
      }
    },
    watch: {
      currentValue: {
        deep: true,
        handler() {
          this.$emit('input', this.currentValue)
        }
      },
      value: {
        deep: true,
        handler() {
          this.currentValue = this.value
        }
      }
    }
  }
</script>
<style lang="sass">
$border-color: #ababab
$bg-color: #e9e9e9
.table-field
  padding: 30px 30px 15px 30px
  .el-button--mini
    margin-left: 0
    font-size: 10px
    padding: 5px
  &__table
    width: 100%
    border-collapse: collapse
    border-spacing: 0
    border: 0
    height: 0
  &__table-block
    border-top: 1px solid $border-color
    border-left: 1px solid $border-color
  &__row-cell, &__head-cell
    padding: 0
  &__head
    background-color: $bg-color
  &__head-inner
    min-height: 30px
    min-width: 50px
  &__head-inner, &__row-inner
    position: relative
    width: 100%
    height: 100%
    border-right: 1px solid $border-color
    border-bottom: 1px solid $border-color
  &__head-input, &__row-input
    height: 100%
    min-height: 50px
  &__head-input .el-input__inner, &__row-input .el-input__inner, &__head-input .el-textarea__inner, &__row-input .el-textarea__inner
    border-width: 0
    border-radius: 0
    padding: 14px 18px
    z-index: 1
    min-height: 100%
    height: 100%
  &__head-input .el-input__inner, &__head-input .el-textarea__inner
    background-color: $bg-color
  &__row-inner
    min-height: 50px
  &__empty-row
    height: 0
  &__empty-cell
    width: 0
    border: 0
    padding: 0
$offset: -12px
.table-field-action
  &__row
    position: absolute
    z-index: 2
    &_add
      top: -11px
    &_delete
      top: 50%
      transform: translateY(-50%)
    &_left
      left: $offset
    &_right
      right: $offset
    &_last
      top: auto
      bottom: $offset
    &_intersected
      right: -27px
  &__column
    position: absolute
    z-index: 2
    &_add
      left: -11px
    &_delete
      left: 50%
      transform: translateX(-50%)
    &_top
      top: $offset
    &_bottom
      bottom: $offset
    &_last
      left: auto
      right: $offset
    &_intersected
      top: -27px
  $red1: rgb(255, 220, 220)
  $red2: rgb(255, 200, 200)
  $green1: rgb(220, 255, 220)
  $green2: rgb(200, 255, 200)
  &__union
    &-right
      position: absolute
      z-index: 2
      top: 50%
      // transform: translateY(-50%)
      right: -11px
      &_increase
        transform: translateY(-50%)
      &_decrease
        background-color: $red1
        transform: translateY(-100%)
        &:hover
          background-color: $red2
      &_decrease + &_increase
        transform: translateY(5%)
    &-bottom
      position: absolute
      z-index: 2
      left: 50%
      // transform: translateX(-50%)
      bottom: -11px
      &_increase
        transform: translateX(-50%)
      &_decrease
        background-color: $red1
        transform: translateX(-105%)
        &:hover
          background-color: $red2
      &_decrease + &_increase
        transform: translateX(5%)


</style>
