<template lang="pug">
  .slug-field
    .slug-field__row
      el-input.slug-field__input(
        v-model="currentValue"
        type="text"
        :required="field.required"
        :placeholder="field.placeholder"
      )
      el-popover.slug-field__help(effect="dark" placement="top" :hide-after="15000" popper-class="slug-field__help-popover")
        .slug-field__help-content {{ field.frontUrlPrefix }}#[span.slug-field__help-slug {{ slugExample }}]
        .slug-field__help-reference(slot="reference") Показать пример
    // @blur='onBlur'
    field-meta(ref="meta" element-name="символов" :len="this.clearValue.length" :min="field.min" :max="field.max" :errors="[]")
</template>
<script>

  import FieldMeta from '@/components/fields/FieldMeta'
  export default {
    name: 'SlugField',
    components: { FieldMeta },
    props: {
      field: { type: Object, default: () => ({}) },
      value: { type: [Number, String], default: null }
    },
    data() {
      let value = ''
      if (typeof this.value === 'number') {
        value = '' + this.value
      } else if (typeof this.value === 'string') {
        value = this.value
      } else if (typeof this.value === 'object' && this.value.toString) {
        value = this.value.toString()
      }
      return {
        errors: [],
        currentValue: value
      }
    },
    watch: {
      currentValue: {
        deep: true,
        handler() {
          this.$emit('input', this.currentValue)
          this.checkErrors()
        }
      },
      value: {
        deep: true,
        handler() {
          if (this.value !== this.currentValue) {
            this.currentValue = this.value
          }
        }
      }
    },
    computed: {
      slugExample() {
        const defaultSlug = 'example'
        return this.currentValue || defaultSlug
      },
      clearValue() {
        return (this.currentValue || '')
          .replace('&nbsp;', ' ')
          .replace(/(&amp;|&lt;|&gt;|&quot;|&apos;)/gmu, '_')
          .replace(/(<[^>]+>|\n)/gmu, '')
          .trim()
      },
      lang() {
        return this.$store.getters.language
      }
    },
    methods: {
      onBlur() {
        this.checkErrors()
      },
      checkErrors() {
        this.$refs.meta.check()
      }
    },
  }
</script>
<style rel="stylesheet/sass" lang="sass">
.slug-field
  position: relative
  min-height: 100%
  padding-bottom: 3px
  &__row
    display: flex
    flex-flow: row nowrap
  &__help
    margin-left: 20px
    text-decoration: underline
    &-reference
      cursor: pointer
    &-slug
      text-decoration: underline
      color: white
    &-popover
      background: #303133
      border-radius: 10px
      color: rgba(255,255,255,0.8)
      &[x-placement^="top"]
        .popper__arrow::after
          border-top-color: #303133
  &__input
    min-height: 100%
    max-width: 400px
  &__bottom
    width: 100%
    display: flex
    justify-content: space-between
  &__errors
    padding-left: 3px
    color: red
    line-height: 12px
    font-size: 9px
    cursor: default
    padding-right: 5px
  &__info
    line-height: 12px
    font-size: 9px
    padding-right: 3px
    text-align: right
    color: #bababa
    cursor: default
</style>
