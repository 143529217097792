<template lang="pug">
  .upload-img-single(:class="{\
    'upload-img-single_horizontal': field.direction === 'horizontal',\
    'upload-img-single_vertical': field.direction === 'vertical',\
    'upload-img-single_contain': contain\
  }")
    el-upload(
      :multiple="true"
      :on-success="handleImageSuccess"
      :on-remove="handleRemove"
      list-type="picture-card"
      drag
      :file-list="fileList"
      :with-credentials="true"
      :data="data"
      :headers="headers"
      :limit="2"
      :action="action"
    )
      i.el-icon-upload
      .el-upload__text
        | Перенесите файл сюда или
        em Нажмите для загрузки
    file-browser(
      :base="$route.meta.model.replace(/__/g, '/').replace('options/', '')"
      :files="files"
      :limit="1"
      @input="emitInput($event[0])"
    )
    field-meta(ref="meta" :check-len="false" style="margin-top: -10px")
      template(slot="default")
        .field-meta__info
          template(v-if="field.sizeWidth || field.sizeHeight") Рекомендуемое разрешение: {{ field.sizeWidth || '...' }}x{{ field.sizeHeight || '...' }}
          template(v-if="field.sizePreferWidth || field.sizePreferHeight") &nbsp;(Retina {{ field.sizePreferWidth || '...' }}x{{ field.sizePreferHeight || '...' }})
      template(slot="right") {{ '' }}

</template>

<script>
import hasUrlGetter from '@/utils/has-url-getter'
import FileBrowser from '@/components/Browser/FileBrowser'
import FieldMeta from '@/components/fields/FieldMeta'
export default {
  name: 'SingleImageUpload',
  mixins: [hasUrlGetter],
  components: { FileBrowser, FieldMeta },
  props: {
    value: {
      type: Object,
      default: null,
    },
    field: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    // console.log(this.value);
    return {
      tempUrl: '',
      additionalData: {},
      fileList: this.getFileList(),
      chanded: 0
    }
  },
  computed: {
    headers() {
      return {
        'Authorization': 'Bearer ' + this.$store.state.user.token
      }
    },
    data() {
      return {
        model: this.$route.meta.model,
        model_id: 0 // TODO: make work loading with model_id. this.$route.params.id
      }
    },
    action() {
      return process.env.VUE_APP_API_BASE + '/files/upload'
    },
    listType() {
      return this.field.list_type || 'picture-card'
    },
    url() {
      return this.value ? this.getUrl(this.value) : ''
    },
    files() {
      return this.value ? [this.value] : []
    },
    contain() {
      return this.field.contain || !!(this.fileList && this.fileList.length && this.fileList[0].url.match(/\.svg$/))
    }
  },
  watch: {
    value: {
      deep: true,
      handler() {
        if (!this.currentValue && this.value) {
          this.changed = true
          this.fileList = this.getFileList()
        }
      }
    }
  },
  methods: {
    getFileList() {
      const image = this.value
      return image ? [{ ...image, url: this.prepareUrl(this.getUrl(image.path)) }] : []
    },
    prepareUrl(url) {
      return this.$imaginary(url, this.field.contain ? 'fit' : 'crop', { width: 156, height: 136, gravity: 'center' })
    },
    getName(str) {
      const splitArr = str.split('/')
      return splitArr[splitArr.length - 1]
    },
    handleRemove(/* file, fileList */) {
      this.emitInput(null)
    },
    emitInput(val) {
      this.$emit('input', val)
    },
    handleImageSuccess(response) {
      // console.log('imageSuccess', response);
      this.emitInput(null)
      this.emitInput(response.data)
    },
  },
}
</script>

<style rel="stylesheet/sass" lang="sass">
@import "@/assets/sass/mixin.scss"
.upload-img-single
  width: 100%
  position: relative

  .el-upload-list
    display: block
    position: relative
    width: 160px
    height: 140px
    border: 1px dashed #c0ccda
    z-index: 2
    &:before
      display: block
      position: absolute
      top: 0px
      width: 100%
      text-align: center
      line-height: 115px
      content: "\e79f"
      font-family: 'element-icons', serif
      speak: none
      font-size: 80px
      color: #C0C4CC
      z-index: 2
    &:after
      display: block
      position: absolute
      width: 100px
      top: 93px
      left: calc(50% - 50px)
      line-height: 16px
      text-align: center
      content: "Изображение отсутствует"
      font-family: 'element-icons', serif
      speak: none
      font-size: 14px
      color: #C0C4CC
      z-index: 2
    &__item
      display: block
      position: absolute
      left: 50%
      top: 0
      margin: 0 0 0 -50%
      width: 100%
      height: 100%
      z-index: 3
  &_vertical
    & > div
      display: flex
      flex-flow: column nowrap
      align-items: center
    .el-upload-list
      margin-bottom: 8px
    .el-upload--picture-card:last-child
      border: 1px dashed #c0ccda
      width: 160px
      height: 75px
    .el-upload-dragger
      background-color: #fbfdff
      border: 0
      border-radius: 6px
      width: 100%
      height: 100%
      transition: opacity 0.5s
      &:hover
        opacity: 0.7
      .el-icon-upload
        margin: 0
        display: block
        line-height: 72px
      .el-upload__text
        display: none
  &_horizontal
    & > div
      display: flex
      flex-flow: row nowrap
      & .el-upload--picture-card:last-child
        height: 140px
        width: 90px
      .el-upload-list
        margin-right: 8px
      .el-upload-dragger
        .el-icon-upload
          margin: 0
          line-height: 140px

    .el-upload-list__item-thumbnail
      object-fit: cover

    // upload more
    .el-upload--picture-card:last-child
      width: 100%
      height: 64px
      display: flex
      justify-content: center
      margin: 0
      background-color: transparent
      border-width: 0
      border-radius: 0

    .el-upload-dragger
      background-color: #fbfdff
      border: 1px dashed #c0ccda
      border-radius: 6px
      width: 160px
      height: 100%
      transition: opacity 0.5s
      &:hover
        opacity: 0.7
      .el-icon-upload
        margin: 4px 0 0
        display: block
      .el-upload__text
        display: none

  &_contain
    & .el-upload-list__item-thumbnail
      object-fit: contain

</style>
