const getNameWithoutNamespaces = (name) => {
  return name.split('__')[name.split('__').length - 1]
}

const removeNamespaces = (values) => {
  const obj = {}
  for (const i in values) {
    const name = getNameWithoutNamespaces(i)
    obj[name] = values[i]
  }
  return obj
}


export default (conditions, values) => {
  if (conditions.length === 0) {
    return false
  }

  values = removeNamespaces(values)

  const isAllOrTrue = conditions.reduce((orFlag, andArr) => {
    const isAllAndTrue = andArr.reduce((andFlag, el) => {
      let value1 = el[0]
      value1 = Object.hasOwnProperty.call(values, value1) && values[value1] !== null ? values[value1] : ''
      const operator = el[1]
      let value2 = el[2]
      if (typeof value2 === 'string' && value2.substr(0, 1) === '$') {
        value2 = values[value2] || ''
      }
      switch (operator) {
      case '=':
        andFlag = andFlag && value1 === value2
        break
      case '!=':
        andFlag = andFlag && value1 !== value2
        break
      case '>=':
        andFlag = andFlag && value1 >= value2
        break
      case '<=':
        andFlag = andFlag && value1 <= value2
        break
      case '>':
        andFlag = andFlag && value1 > value2
        break
      case '<':
        andFlag = andFlag && value1 < value2
        break
      }
      return andFlag
    }, true)
    return orFlag || isAllAndTrue
  }, false)
  return !isAllOrTrue
}
