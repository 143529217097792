<template lang="pug">
  table.repeater-field__table
    thead
      tr.repeater-field__row
        th.repeater-field__field №
        th.repeater-field__field.repeater-field__field-col.repeater-field__field-col_required(
          v-for="(rowField, rowFieldIndex) in field.fields"
          :key="rowFieldIndex"
          :style="`width: ${rowField.width || 100 / field.fields.length}%;`"
        ) {{ rowField.title || rowField.name }}
        th.repeater-field__field.repeater-field__field-action
          el-button.repeater-field__field-action-button.repeater-field__field-action-button-plus(icon="el-icon-plus" size="mini" circle @click.prevent="handleInsert(-1)")
        th.repeater-field__field.repeater-field__field-action(index="repeater-row_action_up_down" v-if="field.resettable")
          el-button.repeater-field__field-action-button(icon="el-icon-refresh" size="mini" circle @click.prevent="$emit('reset')")
        th.repeater-field__field.repeater-field__field-action
          el-button.repeater-field__field-action-button(icon="el-icon-c-scale-to-original" size="mini" circle @click.prevent="openCodeModal")

    draggable(
      tag="tbody"
      v-model="currentValue"
      v-bind="dragOptions"
      v-on="dragEvents"
    )
      tr.repeater-field__draggable-item.repeater-field__row(v-for="(row, rowIndex) in currentValue" :key="row._hash")
        td.repeater-field__field.repeater-field__field-index
          .repeater-field__index {{ rowIndex + 1 }}
          .repeater-field__duplicate
            el-button.repeater-field__duplicate(icon="el-icon-copy-document" size="mini" circle @click.prevent="handleDuplicate(rowIndex)")
        td.repeater-field__field(v-for="(rowField, rowFieldIndex) in field.fields" :key="rowField.name" :ref="`field__${row._hash}__${rowField.name}`" :style="fieldStyles[`field__${row._hash}__${rowField.name}`]")
          condition-field.repeater-field__field-inner(
            :field="rowField"
            v-if="showReInitFields || !reInitFieldTypes.includes(rowField.type)"
            :tableCell="true"
            v-model="currentValue[rowIndex][rowField.name]"
            :allValues="currentValue[rowIndex]"
            :ref="rowField.name"
          )
        td.repeater-field__field.repeater-field__field-action
          el-button.repeater-field__field-action-button.repeater-field__field-action-button-plus(
            icon="el-icon-plus"
            size="mini"
            circle
            @click.prevent="handleInsert(rowIndex)"
          )
          el-button.repeater-field__field-action-button.repeater-field__field-action-button-minus(icon="el-icon-minus" size="mini" circle @click.prevent="handleDelete(rowIndex)")
        td.repeater-field__field.repeater-field__handle
          .repeater-field__handle-inner
            svg-icon(icon-class="move")
        //
          el-button.repeater-field__field-action-button.repeater-field__field-action-button-up(
            icon="el-icon-arrow-up"
            size="mini"
            circle
            @click.prevent="handleUp(rowIndex)" :disabled="rowIndex === 0"
          )
          el-button.repeater-field__field-action-button.repeater-field__field-action-button-down(
            icon="el-icon-arrow-down"
            size="mini"
            circle
            @click.prevent="handleDown(rowIndex)" :disabled="rowIndex === currentValue.length - 1"
          )

</template>
<script>
  import List from './List'

  export default {
    name: 'TableList',
    extends: List,
    data() {
      return {
      }
    },
    computed: {
    },
    methods: {
    },
  }
</script>
